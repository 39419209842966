import React, { FunctionComponent, ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { useSwiperClass } from '../hooks/useSwiperClass'
import { SlideNavigator } from './slide-navigator'
import { SlideControls } from './slide-controls'

const Outer = styled.div`
  display: grid;
  grid-template-columns: 20rem 1fr;
  @media (max-width: 40rem){
    display: flex;
    flex-direction: column;
  }
`

const SliderContainer = styled.div`
  position: relative;
  min-width: 0;
  @media (max-width: 40rem){
    margin-left: calc(var(--page-margin) * -1);
    margin-right: calc(var(--page-margin) * -1);
  }
  .swiper-wrapper {
    @media (max-width: 40rem){
      padding-left: var(--page-margin);
      padding-right: var(--page-margin);
    }
  }
  .swiper-slide {
    width: fit-content;
    height: auto;
    &:not(:last-child) {
      margin-right: 2.5rem;
    }
  }
`
const SliderContainerInner = styled.div`
  overflow: hidden;
`

const NavigatorContainer = styled.div`
  @media (max-width: 40rem){
    padding-top: 2rem;
    max-width: 100vw;
  }
`

const NavigatorInner = styled.div`
  padding-top: 5rem;
  @media (max-width: 40rem){
    padding-top: 2rem;
  }
`

const NavigatorLabel = styled.div`
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 1.7272727272727273;
  letter-spacing: 0.08em;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 1.3571428571428572;
  }
`

const ControlsContainer = styled.div`
  padding-right: calc((100vw - min(calc(100vw - var(--page-margin) * 2), var(--page-max-width))) / 2);
  position: absolute;
  top: calc(12rem - 1.25rem);
  left: 0;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
`

const ControlsContainerInner = styled.div`
  margin: 0 -1.25rem;
  @media (max-width: 40rem){
    margin: 0.25rem;
  }
`

type SlideWithNavigatorItem = {
  label: ReactNode,
  leftLabel?: ReactNode,
  content: ReactNode
}

type SlideWithNavigatorProps = {
  navigatorLabel?: ReactNode
  items: SlideWithNavigatorItem[]
  onSwiper?: (swiper: SwiperClass | undefined) => void
}
export const SlideWithNavigator: FunctionComponent<SlideWithNavigatorProps> = ({navigatorLabel, items, onSwiper}) => {
  const {ref, activeIndex, slideTo, swiper} = useSwiperClass()
  useEffect(() => {
    onSwiper?.(swiper)
  }, [swiper])
  return <Outer>
    <NavigatorContainer>
      {
        navigatorLabel && <NavigatorLabel>
          {navigatorLabel}
        </NavigatorLabel>
      }
      <NavigatorInner>
        <SlideNavigator
          labels={items.map(({label, leftLabel}) => ({label, leftLabel}))}
          activeIndex={activeIndex}
          onChange={slideTo}
        />
      </NavigatorInner>
    </NavigatorContainer>
    <SliderContainer>
      <SliderContainerInner>
        <Swiper
          onSwiper={ref}
          slidesPerView="auto"
          onReachEnd= {function(this: SwiperClass) {
            //@ts-ignore
            this.snapGrid = [...this.slidesGrid];
          }}
        >
          {
            items.map((item, i) => {
              return <SwiperSlide key={i}>
                {item.content}
              </SwiperSlide>
            })
          }
        </Swiper>
      </SliderContainerInner>
      <ControlsContainer>
        <ControlsContainerInner>
          <SlideControls swiper={swiper}/>
        </ControlsContainerInner>
      </ControlsContainer>
    </SliderContainer>
  </Outer>
}