import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SvgRightAngle } from './svg'

const Outer = styled.div`
  width: 100%;
`

const AngleContainer = styled.div`
  display: flex;
`

const AngleOuter = styled.div`
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  &:first-child {
    justify-content: flex-start;
  }
  &:last-child {
    justify-content: flex-end;
  }
  svg {
    display: block;
    height: 1.5rem;
    width: auto;
    transform: scalex(40%) rotate(90deg);
    path {
      stroke: none;
      fill: currentColor;
    }
  }
`

const BarOuter = styled.div`
  display: flex;
  height: 0.75rem;
  border: 1px solid currentColor;
`

const BarItem = styled.div`
  flex: 1 1 0;
  background-color: var(--color);
`

const LabelContainer = styled.div`
  display: flex;
`

const LabelItem = styled.div`
  font-size: 0.75rem;
  flex: 1 1 0;
  text-align: center;
  padding: 0 1rem;
  margin-top: 0.5rem;
  &:first-child {
    text-align: left;
  }
  &:last-child {
    text-align: right;
  }
`


type BarProps = {
  values: {
    id: string,
    color: string,
    label: string,
    active?: boolean
  }[],
}
export const Bar: FunctionComponent<BarProps> = ({values}) => {
  return <Outer>
    <AngleContainer>
      {
        values.map((value, i) => {
          return <AngleOuter key={i}>
            {
              value.active && <SvgRightAngle size={24}/>
            }
          </AngleOuter>
        })
      }
    </AngleContainer>
    <BarOuter>
      {
        values.map((value, i) => {
          return <BarItem key={i} style={{'--color': value.active ? value.color : 'transparent'}}/>
        })
      }
    </BarOuter>
    <LabelContainer>
      {
        values.map((value, i) => {
          return <LabelItem key={i}>
            {value.label}
          </LabelItem>
        })
      }
    </LabelContainer>
  </Outer>
}