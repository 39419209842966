import * as React from "react";
import type { SVGProps } from "react";
const SvgG33 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={442}
    height={181}
    fill="none"
    viewBox="0 0 442 181"
    {...props}
  >
    <path
      fill="#D9AFCB"
      stroke="#D9AFCB"
      strokeMiterlimit={10}
      strokeWidth={0.5}
      d="M1 95.378V73.465c18.61 0 21.54 12.174 40.181 12.174s21.54-12.174 40.181-12.174c17.503 0 22.039 10.754 40.181 12.174 15.867 1.233 21.119-8.71 35.661-11.565a24 24 0 0 1 8.884-.047c14.604 2.622 19.779 11.612 35.77 11.612 17.348 0 21.088-10.551 36.518-12.002a28.5 28.5 0 0 1 7.014.218c15.415 2.372 20.449 11.784 36.815 11.784 18.033 0 21.368-11.41 38.451-12.127 1.028-.047 2.072 0 3.101.109v21.913c-1.029-.11-2.073-.156-3.101-.11-17.083.734-20.403 12.128-38.451 12.128-16.366 0-21.4-9.412-36.815-11.784a28.5 28.5 0 0 0-7.014-.218c-15.445 1.467-19.186 12.002-36.518 12.002-15.991 0-21.166-8.975-35.77-11.612a24.3 24.3 0 0 0-8.884.047c-14.542 2.84-19.794 12.798-35.661 11.565-18.127-1.42-22.662-12.174-40.181-12.174-18.625 0-21.571 12.174-40.181 12.174S19.61 95.378 1 95.378Z"
    />
    <path
      fill="#D9AFCB"
      fillOpacity={0.4}
      stroke="#D9AFCB"
      strokeMiterlimit={10}
      strokeWidth={0.5}
      d="M1.655 108.534c18.61 0 20.9 11.488 39.526 11.488s21.54-12.174 40.181-12.174c17.503 0 22.039 10.753 40.181 12.174 15.867 1.233 21.119-8.709 35.661-11.566a24 24 0 0 1 8.884-.046c14.604 2.622 19.779 11.612 35.77 11.612 17.348 0 21.088-10.551 36.519-12.003a28.5 28.5 0 0 1 7.013.219c15.415 2.372 20.449 11.784 36.815 11.784 18.033 0 21.368-11.41 38.451-12.128 1.028-.046 2.073 0 3.101.11.546 2.216.39 4.214.39 6.492 0 36.507-72.335 66.099-161.55 66.099S1.047 151.003 1.047 114.496c0-2.013.218-3.995.654-5.962zM161.901 1C79.264 1 11.121 26.378 1.505 59.138c-.28.968.374 1.967 1.371 2.03 16.506.983 19.982 12.095 37.797 12.095s21.54-12.174 40.18-12.174c17.504 0 22.039 10.754 40.182 12.174 15.866 1.233 21.119-8.709 35.645-11.565 2.93-.578 5.954-.578 8.9-.047 14.604 2.622 19.763 11.612 35.77 11.612 17.347 0 21.088-10.55 36.518-12.002 17.94-1.577 25.857 12.158 43.828 12.002 17.831.094 22.74-12.174 39.121-12.143 1.045 0 1.793-1.03 1.481-2.029C312.635 26.362 244.508 1 161.917 1z"
    />
    <path
      fill="#AF6F9A"
      d="M381.615 56v-9.8h2.156v7.84h4.886V56zm7.828 0 4.2-9.87h1.988l4.2 9.87h-2.254l-.896-2.198h-4.144L391.641 56zm3.864-4.102h2.604l-1.302-3.178zm9.25 4.102v-3.864l-3.766-5.936h2.52l2.338 3.934 2.38-3.934h2.45l-3.766 5.894V56zm7.264 0v-9.8h7.392v1.918h-5.25v1.988h4.62v1.918h-4.62v2.058h5.32V56zm9.378 0v-9.8h4.48c1.246 0 2.212.35 2.856.994.546.546.84 1.316.84 2.24v.028c0 1.582-.854 2.576-2.1 3.038l2.394 3.5h-2.52l-2.1-3.136h-1.694V56zm2.156-5.04h2.184c1.05 0 1.652-.56 1.652-1.386v-.028c0-.924-.644-1.4-1.694-1.4h-2.142zM434.83 56v-7.686l-1.708.42-.448-1.764 2.814-.84h1.47V56z"
    />
    <circle cx={295.939} cy={50.5} r={3.5} fill="#AF6F9A" />
    <path stroke="#AF6F9A" d="M298.439 50.5h77" />
    <path
      fill="#AF6F9A"
      d="M381.615 96v-9.8h2.156v7.84h4.886V96zm7.828 0 4.2-9.87h1.988l4.2 9.87h-2.254l-.896-2.198h-4.144L391.641 96zm3.864-4.102h2.604l-1.302-3.178zm9.25 4.102v-3.864l-3.766-5.936h2.52l2.338 3.934 2.38-3.934h2.45l-3.766 5.894V96zm7.264 0v-9.8h7.392v1.918h-5.25v1.988h4.62v1.918h-4.62v2.058h5.32V96zm9.378 0v-9.8h4.48c1.246 0 2.212.35 2.856.994.546.546.84 1.316.84 2.24v.028c0 1.582-.854 2.576-2.1 3.038l2.394 3.5h-2.52l-2.1-3.136h-1.694V96zm2.156-5.04h2.184c1.05 0 1.652-.56 1.652-1.386v-.028c0-.924-.644-1.4-1.694-1.4h-2.142zM433.024 96v-1.708l3.262-2.674c1.218-1.008 1.694-1.54 1.694-2.352 0-.826-.546-1.274-1.316-1.274-.756 0-1.274.42-2.002 1.316l-1.512-1.218c.966-1.316 1.904-2.03 3.654-2.03 2.03 0 3.374 1.19 3.374 3.024v.028c0 1.638-.84 2.45-2.576 3.794l-1.596 1.232h4.27V96z"
    />
    <circle cx={295.939} cy={90.5} r={3.5} fill="#AF6F9A" />
    <path stroke="#AF6F9A" d="M298.439 90.5h77" />
    <path
      fill="#AF6F9A"
      d="M381.615 138v-9.8h2.156v7.84h4.886V138zm7.828 0 4.2-9.87h1.988l4.2 9.87h-2.254l-.896-2.198h-4.144l-.896 2.198zm3.864-4.102h2.604l-1.302-3.178zm9.25 4.102v-3.864l-3.766-5.936h2.52l2.338 3.934 2.38-3.934h2.45l-3.766 5.894V138zm7.264 0v-9.8h7.392v1.918h-5.25v1.988h4.62v1.918h-4.62v2.058h5.32V138zm9.378 0v-9.8h4.48c1.246 0 2.212.35 2.856.994.546.546.84 1.316.84 2.24v.028c0 1.582-.854 2.576-2.1 3.038l2.394 3.5h-2.52l-2.1-3.136h-1.694V138zm2.156-5.04h2.184c1.05 0 1.652-.56 1.652-1.386v-.028c0-.924-.644-1.4-1.694-1.4h-2.142zm15.337 5.208c-1.764 0-2.982-.7-3.822-1.694l1.484-1.414c.672.756 1.372 1.176 2.366 1.176.812 0 1.386-.462 1.386-1.19v-.028c0-.798-.714-1.246-1.918-1.246h-.896l-.336-1.372 2.338-2.352h-3.85V128.2h6.622v1.624l-2.478 2.366c1.33.224 2.632.924 2.632 2.744v.028c0 1.848-1.344 3.206-3.528 3.206"
    />
    <circle cx={295.939} cy={132.5} r={3.5} fill="#AF6F9A" />
    <path stroke="#AF6F9A" d="M298.439 132.5h77" />
  </svg>
);
export default SvgG33;
