import { useEffect, useState } from 'react'
import { SwiperClass } from 'swiper/react'
import { SwiperEvents } from 'swiper/types'

export const useOn = <E extends keyof SwiperEvents>(swiper: SwiperClass | undefined, event: E, listener: SwiperEvents[E]) => {
  useEffect(() => {
    if(swiper){
      swiper.on(event, listener)
      return () => {
        swiper.off(event, listener)
      }
    }
  }, [swiper])
}

export const useSwiperClass = () => {
  const [swiper, setSwiper] = useState<SwiperClass>()
  const [activeIndex, setActiveIndex] = useState(0)
  const [slideCount, setSlideCount] = useState(0)
  const slideTo = (index: number) => swiper?.slideTo(index)
  const slidePrev = () => {
    swiper?.slidePrev()
  }
  const slideNext = () => {
    swiper?.slideNext()
  }

  useOn(swiper, 'activeIndexChange', swiper => setActiveIndex(swiper.realIndex))
  useOn(swiper, 'slidesLengthChange', swiper => setSlideCount(swiper.slides.length))
  useOn(swiper, 'init', swiper => setSlideCount(swiper.slides.length))
  return {
    ref: setSwiper,
    swiper,
    activeIndex,
    slideTo,
    slidePrev,
    slideNext,
    slideCount
  }
}