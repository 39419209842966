import * as React from "react";
import type { SVGProps } from "react";
const SvgSetGraphA02 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 103.95 100.5"
    {...props}
  >
    <path
      fill="rgba(255, 255, 255, .5)"
      stroke="#5698a3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M28.07 46.13c-11.7-11.08 8.08-26.07 15.6-11.84 0 0 2.68 6.88 9.19 5.32.92-.22 1.79-.64 2.59-1.14.85-.53 2.37-1.3 4.34-1.38 11-.44 10.5 16.93-.5 16.32-3.43.01-5.39-1.48-7.06-2.16-5.75-2.36-11.09-1.75-13.31 2.16-.49.87-.75 1.86-.84 2.85-.61 6.28-1.92 14.2-4.7 15.49-8.71 6.9-18.36-7.27-8.8-12.85l-.03-.04c10.76-6.7 3.5-12.72 3.5-12.72z"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M42.5 99.37c9.3-.66 12.32-15.27 4.51-20.48-5.16-2.82-9.89-1.59-14.53 3.37-1.68 1.73-4.56 1.61-6.85-.5-10.04-9.22-16.82 4.44-11.07 9.97 1.47 1.42 3.5 1.93 5.48 1.58 1.45-.25 2.96-.92 4.93-2.38 6.23-5.02 5.52 9.54 17.51 8.45"
    />
    <path
      fill="none"
      stroke="#5698a3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M42.5 99.37c9.3-.66 12.32-15.27 4.51-20.48-5.16-2.82-9.89-1.59-14.53 3.37-1.68 1.73-4.56 1.61-6.85-.5-10.04-9.22-16.82 4.44-11.07 9.97 1.47 1.42 3.5 1.93 5.48 1.58 1.45-.25 2.96-.92 4.93-2.38 6.23-5.02 5.52 9.54 17.51 8.45"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M.44 51.7c1.31 8.83 15.46 10.65 19.87 2.82h.01c2.31-5.13.79-9.55-4.28-13.62-1.77-1.48-1.86-4.23-.02-6.57 8.07-10.25-5.46-15.73-10.32-9.84-1.24 1.51-1.58 3.48-1.11 5.34.35 1.36 1.09 2.76 2.63 4.53 5.24 5.58-8.7 5.96-6.79 17.32"
    />
    <path
      fill="none"
      stroke="#5698a3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M.44 51.7c1.31 8.83 15.46 10.65 19.87 2.82h.01c2.31-5.13.79-9.55-4.28-13.62-1.77-1.48-1.86-4.23-.02-6.57 8.07-10.25-5.46-15.73-10.32-9.84-1.24 1.51-1.58 3.48-1.11 5.34.35 1.36 1.09 2.76 2.63 4.53 5.24 5.58-8.7 5.96-6.79 17.32"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      stroke="#5698a3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M76.9 41.59c2.83-1.27 7.65-1.19 9.61 1.21 7.64 9.31-8.19 20.67-.65 25.46 15.25 9.69 6.25 22.53-2.15 22.61-14.48.13-11.6-15.79-22.82-17.02-4.64-.51-6.93 1.75-12.36-.31 0 0-6.23-3.9-2.06-10.79 3.02-5 11.15-3.99 15.72.33 2.34 2.47 5.66 3.8 9.05 1.73 9.54-5.42-5.33-18.29 5.66-23.22M6.75 70.84c.16 3.34 3.57 5.74 6.66 4.46 2.12-.88 3.63-3.07 2.64-6.51-1.97-6.89-6.31-6.38-8.59-1.85-.51 1-.79 2.48-.72 3.91zM62.55 100.25c3.998 0 7.24-3.46 7.24-7.73s-3.242-7.73-7.24-7.73-7.24 3.46-7.24 7.73 3.241 7.73 7.24 7.73M103.56 49.57c-.54 2.02-2.75 4.11-4.64 3.6s-2.76-3.42-2.21-5.44c.54-2.02 2.52-3.25 4.41-2.74s2.99 2.56 2.44 4.58"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      stroke="#5698a3"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M103.7 65.82c0 3.52-1.36 6.37-4.66 6.37s-7.27-2.85-7.27-6.37 2.67-6.37 5.96-6.37 5.96 2.85 5.96 6.37zM99 29.15c-.26-4.69-3.57-9.07-11.47-8.82h-.13c-3.6.08-6.05.88-8.26-4.6-.06-.15-.61-1.59-.67-1.73-4-8.51-19.45-19.02-30.97-10.74-1.25.9-2.49 1.82-3.77 2.69-2.05 1.46-9.22 1.74-11.54.76-4.61-2.51-10.53-1.86-13.22 1.66-3.21 3.33-2.79 9.51.79 12.36 3.77 2.78 6.88 2.87 12.78 1.8 2.14-.42 7.78-2.53 12.14 1.8h.01c2.5 3.28 6.37 5.75 10.99 6.64v.02s6.3 1.81 14.01-2.3c6.53-3.49 9.74-.41 12.5 3.09 3.37 4.28 7.84 5.36 8.85 5.59 3.09.72 7.51-.25 7.93-4.77.07-.71.13-2.81.04-3.48zM6.1 63.9c0 1.06-.86 2.57-1.92 2.57s-2.82-1.17-2.37-2.9c.27-1.03 1.31-1.58 2.37-1.58s1.92.86 1.92 1.92z"
    />
  </svg>
);
export default SvgSetGraphA02;
