import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { AssetImage } from '../components/image'
import { pageMaxWidth } from '../styles/page-max-width'

const Outer = styled.div``

const Inner = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @media (max-width: 60rem){
    grid-template-columns: 1fr;
  }
`

const Section = styled.div`
  position: relative;
  @media (max-width: 60rem){
    &:nth-child(2){
      order: -1;
    }
    &:nth-child(4){
      order: 1;
    }
  }
`

const Bg = styled.div`
  position: relative;
  @media (max-width: 60rem){
    div + & {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const SectionInner1 = styled.div`
  ${pageMaxWidth}
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  z-index: 1;
  @media (max-width: 60rem){
    padding-top: 4rem;
    position: relative;
    top: auto;
    left: auto;
    width: auto;
    height: auto;
    min-height: 30rem;
  }
`

const SectionInner2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 auto;
`

const Text1 = styled.div`
  font-size: 1.25rem;
  line-height: 1.55;
  letter-spacing: 0.06em;
  font-weight: 700;
  &:not(:first-child) {
    margin-top: 4rem;
    font-size: 1rem;
    line-height: 1.603124976158142;
  }
`

const Text2 = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  margin-top: 0.75rem;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 1.7142857142857142;
  }
  br {
    display: none;
  }
`

const Note = styled.div`
  font-size: 0.5625rem;
  line-height: normal;
  margin-top: 1.5rem;
`


export type ProductDetailSectionData = {
  img1: string,
  img2: string,
  img3: string,
  img4: string,
  text1: ReactNode,
  text2: ReactNode,
  text3: ReactNode,
  text4: ReactNode,
  note1: ReactNode,
  text5: ReactNode,
  text6: ReactNode,
  note2: ReactNode
}

type ProductDetailSectionProps = {
  data: ProductDetailSectionData
}
export const ProductDetailSection: FunctionComponent<ProductDetailSectionProps> = ({data}) => {
  return <Outer>
    <Inner>
      <Section>
        <SectionInner1>
          <SectionInner2>
            <Text1>
              {data.text1}
            </Text1>
            <Text2>
              {data.text2}
            </Text2>
            <Text1>
              {data.text3}
            </Text1>
            <Text2>
              {data.text4}
            </Text2>
          </SectionInner2>
          <Note>
            {data.note1}
          </Note>
        </SectionInner1>
        <Bg>
          <AssetImage src={data.img1} />
        </Bg>
      </Section>
      <Section>
        <Bg>
          <AssetImage src={data.img2} />
        </Bg>
      </Section>
      <Section>
        <Bg>
          <AssetImage src={data.img3} />
        </Bg>
      </Section>
      <Section>
        <SectionInner1>
          <SectionInner2>
            <Text1>
              {data.text5}
            </Text1>
            <Text2>
              {data.text6}
            </Text2>
          </SectionInner2>
          <Note>
            {data.note2}
          </Note>
        </SectionInner1>
        <Bg>
          <AssetImage src={data.img4} />
        </Bg>
      </Section>
    </Inner>
  </Outer>
}