import * as React from "react";
import type { SVGProps } from "react";
const SvgClose = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="none"
      stroke="currentcolor"
      strokeMiterlimit={10}
      strokeWidth={1.001}
      d="m1.5 18.485 17-16.97M1.5 1.515l17 16.97"
    />
  </svg>
);
export default SvgClose;
