import classNames from 'classnames'
import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { useBreakpoint } from '../hooks/use-break-point'
import { Animate } from './animate'

const Outer = styled.div`
`

const HeadContainer = styled.div`
  width: fit-content;
  height: fit-content;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 4.75rem 1rem;
  --row-height: 0.6875rem;
  @media (max-width: 40rem){
    grid-template-columns: none;
    grid-auto-columns: var(--row-height);
    grid-template-rows: auto auto auto;
    gap: 1rem 6rem;
    padding-bottom: 4rem;
    margin-left: calc(var(--page-margin) * -1);
    margin-right: calc(var(--page-margin) * -1);
    padding-left: var(--page-margin);
    padding-right: var(--page-margin);
    box-sizing: border-box;
    width: calc(100% + var(--page-margin) * 2);
    overflow-x: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;
  }
`

const HeadItem = styled.div`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: 1 / span 3;
  grid-row: calc(var(--index) + 1);
  cursor: pointer;
  z-index: 1;
  opacity: 0;
  transition: opacity;
  .animate & {
    opacity: 1;
    transition-duration: 0.4s;
    transition-delay: calc(var(--index) * 0.1s + 0.6s);
  }
  @media (max-width: 40rem){
    grid-column: calc(var(--index) + 1);
    grid-row: 1 / span 3;
    grid-template-rows: subgrid;
    grid-template-columns: none;
  }
`

const HeadPoint = styled.div`
  border: 1px solid currentColor;
  width: var(--row-height);
  height: var(--row-height);
  border-radius: 10rem;
  background-color: var(--light-gray);
  box-sizing: border-box;
  @media (max-width: 40rem){
    
  }
  ${HeadItem}.active & {
    border-color: var(--color);
    background-color: var(--color);
  }
`

const Label = styled.div`
  height: var(--row-height);
  font-size: 0.75rem;
  line-height: 1.4166666666666667;
  font-family: var(--en-font);
  font-weight: 700;
  margin-top: -0.125rem;
  line-height: normal;
  transition: transform;
  transform: translateX(1rem);
  @media (max-width: 40rem){
    text-align: center;
    white-space: nowrap;
    width: fit-content;
    position: relative;
    font-size: 0.625rem;
    line-height: 1.5;
    letter-spacing: 0.08em;
    transform: translateY(1rem) translateX(calc(-50% + var(--row-height) / 2));
  }
  .animate & {
    transform: none;
    transition-duration: 0.4s;
    transition-delay: calc(var(--index) * 0.2s + 0.4s);
    @media (max-width: 40rem){
      transform: translateX(calc(-50% + var(--row-height) / 2));
    }
  }
`

const LeftLabel = styled(Label)`
`

const RightLabel = styled(Label)`
`

const HeadLine = styled.div`
  grid-column: 2;
  height: 100%;
  position: relative;
  @media (max-width: 40rem){
    grid-column: auto;
    grid-row: 2;
    height: auto;
    width: 100%;
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: calc(var(--row-height) / 2);
    left: 50%;
    height: calc(100% - var(--row-height));
    background-color: currentColor;
    width: 1px;
    transform-origin: 0 0;
    transform: scaleY(0) translateX(-50%);
    transition: transform;
    @media (max-width: 40rem){
      top: 50%;
      left: calc(var(--row-height) / 2);
      height: 1px;
      width: calc(100% - var(--row-height));
      transform: scaleX(0) translateY(-50%);
    }
    .animate & {
      transform: scaleY(1) translateX(-50%);
      transition-duration: 1s;
      transition-delay: 0.4s;
      @media (max-width: 40rem){
        transform: scaleX(1) translateY(-50%);
      }
    }
  }
`

type SlideNavigatorProps = {
  labels: {
    leftLabel?: ReactNode,
    label: ReactNode,
    color?: string
  }[]
  onChange: (activeIndex: number) => void 
  activeIndex: number
}
export const SlideNavigator: FunctionComponent<SlideNavigatorProps> = ({labels, onChange, activeIndex}) => {
  const isMobile = useBreakpoint('40rem')
  return <Outer>
    <Animate>
      <HeadContainer>
        <HeadLine style={{[isMobile ? 'gridColumn': 'gridRow']: `1 / ${labels.length + 1}`}}/>
        {
          labels.map((item, i) => {
            return <HeadItem key={i} onClick={() => onChange(i)} className={classNames({active: activeIndex === i})} style={{'--index': i}}>
              <LeftLabel>
                {item.leftLabel}
              </LeftLabel>
              <HeadPoint style={{'--color': item.color ?? 'currentColor'}}/>
              <RightLabel>
                {item.label}
              </RightLabel>
            </HeadItem>
          })
        }
      </HeadContainer>
    </Animate>
  </Outer>
}