import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { pageMaxWidthWithPadding } from '../styles/page-max-width'
import SvgRetinoDermGraph01 from '../generated/svg/retino-derm-graph-01'

const Outer = styled.div`
  background-color: #fff;
  padding-top: 4rem;
  padding-bottom: 4rem;
`

const Inner = styled.div`
  ${pageMaxWidthWithPadding}
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4rem;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }
`

const GraphContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`

const TextContainer = styled.div`
  @media (max-width: 40rem){
    display: contents;
  }
`

const Text1 = styled.div`
  font-size: 1.25rem;
  line-height: 1.6;
  letter-spacing: 0.01em;
  font-weight: 700;
  @media (max-width: 40rem){
    font-size: 1rem;
    line-height: 1.603124976158142;
    text-align: center;
    order: -1;
  }
`

const Text2 = styled.div`
  line-height: 1.6;
  letter-spacing: 0.01em;
  margin-top: 1.5rem;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 1.7142857142857142;
    margin-top: 0;
  }
`

type RetinoDermoSectionProps = {

}
export const RetinoDermoSection: FunctionComponent<RetinoDermoSectionProps> = () => {
  return <Outer>
    <Inner>
      <GraphContainer>
        <SvgRetinoDermGraph01 />
      </GraphContainer>
      <TextContainer>
        <Text1>
          レチノール初心者の方や、<br />
          敏感肌の方におすすめの使用方法
        </Text1>
        <Text2>
          顔全体、特にA反応の出やすい目元・口元・小鼻周りといった部分に、保湿クリーム（ナイアシナグロージェル）＋レチノール＋保湿クリームで交互に塗っていただきケアすると、クリームが肌の潤いバリアを整え、より優しくケアできます。
        </Text2>
      </TextContainer>
    </Inner>
  </Outer>
}