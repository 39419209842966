import React, { FunctionComponent, ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import { rightSideMaxWidth } from '../styles/page-max-width'
import { slideWidth } from '../styles/slide-width'
import SvgLeftAngle from '../generated/svg/left-angle'
import SvgRightAngle from '../generated/svg/right-angle'
import { useSwiperClass } from '../hooks/useSwiperClass'
import classNames from 'classnames'
import { useBreakpoint } from '../hooks/use-break-point'
import { useSettings } from '../hooks/use-settings'

const Outer = styled.div`
`

const Inner = styled.div`
  ${rightSideMaxWidth}
  background-color: #F4F4F4;
  border-radius: 3rem 0 0 3rem;
  padding-top: 4rem;
  padding-bottom: 4rem;
  overflow-x: hidden;
  @media (max-width: 40rem){
    border-radius: 2rem;
  }
`

const TopRow = styled.div`
  padding-left: 1rem;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 0.25rem;
    background-color: var(--color);
    height: calc(100% - 0.75rem);
    left: 0;
    top: 0.25rem;
  }
  @media (max-width: 40rem){
    padding-left: 0;
    &::before {
      left: -1rem;
    }
  }
`

const Text1 = styled.div`
  font-size: 1.375rem;
  letter-spacing: 0.06em;
  font-weight: 700;
  @media (max-width: 40rem){
    font-size: 1.125rem;
    line-height: normal;
  }
`

const Text2 = styled.div`
  font-size: 0.875rem;
  line-height: 2.0714285714285716;
  margin-top: 0.5rem;
  @media (max-width: 40rem){
    font-size: 0.8125rem;
    line-height: 1.7369091327373798;
  }
`

const Container1 = styled.div`
  display: grid;
  grid-template-columns: 6fr 4fr;
  gap: 4rem;
  margin-top: 4rem;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
    gap: 2rem;
  }

`

const GraphContainer = styled.div`
  svg, img, video {
    display: block;
    width: 100%;
    height: auto;
  }
`

const Text3 = styled.div`
  font-size: 0.875rem;
  line-height: 2;
`

const SliderContainer = styled.div`
  position: relative;
  margin-top: 4rem;
  --slides-per-view: 3;
  --gap: 2rem;
  .swiper-wrapper:not(:has(.swiper-slide:nth-child(3))){
    --slides-per-view: 2;
  }
  @media (max-width: 40rem){
    --slides-per-view: 2;
  }
  .swiper-slide {
    ${slideWidth}
  }
`

const Item = styled.div`
  display: flex;
  gap: 1rem;
  @media (max-width: 60rem){
    flex-direction: column;
  }
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  svg {
    display: block;
    height: 6.25rem;
    width: auto;
    @media (max-width: 40rem){
      width: 100%;
      height: auto;
      max-width: 8rem;
    }
  }

`

const ItemInner = styled.div`

`

const Name = styled.div`
  font-weight: 700;
  line-height: 1.25;
  @media (max-width: 40rem){
    font-size: 0.8125rem;
  }
`

const Text4 = styled.div`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.8571428571428572;
  @media (max-width: 40rem){
    font-size: 0.8125rem;
    line-height: 1.6153846153846154;
  }
`

const Text5 = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  color: var(--color);
  text-align: center;
  border: 1px solid var(--color);
  margin-top: 1rem;
  padding: 0.25rem;
  font-weight: 500;
`

const Note = styled.div`
  margin-top: 2.5rem;
  font-size: 0.5625rem;
  line-height: normal;
  text-align: right;
  @media (max-width: 40rem){
    font-size: 0.5625rem;
    line-height: 1.5555555555555556;
    text-align-last: left;
  }
`

const ControlsContainer = styled.div`
  --offset: 3rem;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: calc(100% + var(--offset) * 2);
  margin: 0 calc(var(--offset) * -1);
  height: 100%;
  position: absolute;
  align-items: center;
  @media (max-width: 40rem){
    --offset: 1.5rem;
  }
`

const ArrowContainer = styled.div`
  pointer-events: all;
  cursor: pointer;
  &.disabled {
    opacity: 0.25;
    pointer-events: none;
  }
  svg {
    display: block;
    height: 1.5rem;
    width: auto;
  }
`

type ProductDetail2Data = {
  text1: ReactNode,
  text2: ReactNode,
  text3: ReactNode,
  graph: string,
  items: {
    icon: ReactNode,
    name: ReactNode,
    text1: ReactNode
    text2?: ReactNode
  }[],
  note: ReactNode,
  color: string
}
type ProductDetail2Props = {
  data: ProductDetail2Data
}
export const ProductDetail2: FunctionComponent<ProductDetail2Props> = ({data}) => {
  const {ref, activeIndex, slideCount, slideNext, slidePrev, swiper} = useSwiperClass()
  const videoRef = useRef<HTMLVideoElement>(null)
  const isMobile = useBreakpoint('40rem')
  const {themeFileRootPath} = useSettings()
  useEffect(() => {
    const video = videoRef.current
    if(video){
      video.playbackRate = 1.5
    }
  }, [])
  return <Outer style={{'--color': data.color}}>
    <Inner>
      <TopRow>
        <Text1>{data.text1}</Text1>
        <Text2>{data.text2}</Text2>
      </TopRow>
      <Container1>
        <GraphContainer>
          <video src={`${themeFileRootPath}/assets/bundle/images/${data.graph}`} autoPlay loop muted playsInline ref={videoRef}/>
        </GraphContainer>
        <Text3>{data.text3}</Text3>
      </Container1>
      <SliderContainer>
        <Swiper
          slidesPerView="auto"
          onSwiper={ref}
        >
          {
            data.items.map((item, i) => {
              return <SwiperSlide key={i}>
                <Item>
                  <IconContainer>
                    {item.icon}
                  </IconContainer>
                  <ItemInner>
                    <Name>{item.name}</Name>
                    <Text4>{item.text1}</Text4>
                    {
                      item.text2 && <Text5>
                        {item.text2}
                      </Text5>
                    }
                  </ItemInner>
                </Item>
              </SwiperSlide>
            })
          }
        </Swiper>
        {
          (data.items.length > 3 || isMobile) && <ControlsContainer>
            <ArrowContainer className={classNames({disabled: activeIndex === 0})} onClick={() => slidePrev()}>
              <SvgLeftAngle />
            </ArrowContainer>
            <ArrowContainer className={classNames({disabled: activeIndex === slideCount - 1})} onClick={() => slideNext()}>
              <SvgRightAngle />
            </ArrowContainer>
          </ControlsContainer>
        }
      </SliderContainer>
      <Note>{data.note}</Note>
    </Inner>
  </Outer>
}