import styled from 'styled-components'

export const SectionHead = styled.h2`
  font-family: var(--serif-en-font);
  font-weight: 400;;
  font-size: 3rem;
  letter-spacing: 0.06em;
  text-indent: -1rem;
  @media (max-width: 40rem){
    font-size: 2.1875rem;
  }
`