import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import SvgSetGraph02 from '../generated/svg/set-graph-02'
import { AssetImage } from '../components/image'
import { pageMaxWidthWithPadding } from '../styles/page-max-width'

const Outer = styled.div`
  position: relative;
`

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const Inner = styled.div`
  ${pageMaxWidthWithPadding}
  display: grid;
  grid-template-columns: 5fr 5fr;
  gap: 4rem;
  position: relative;
  z-index: 1;
  padding-top: 6rem;
  padding-bottom: 6rem;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: 40rem){
    order: -1;
  }
`

const Text1 = styled.div`
  font-size: 1.375rem;
  line-height: 1.7272727272727273;
  letter-spacing: 0.08em;
  color: white;
  font-weight: 700;
  @media (max-width: 40rem){
    font-size: 1.125rem;
    line-height: 1.7222222222222223;
  }
`

const Text2 = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  margin-top: 1.5rem;
  color: white;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 1.7142857142857142;
    margin-top: 1rem;
  }
`

const Text3 = styled.div`
  font-size: 0.5625rem;
  line-height: normal;
  margin-top: auto;
  color: white;
  padding-top: 1.5rem;
  @media (max-width: 40rem){
    font-size: 0.5625rem;
    line-height: normal;
    opacity: 0.75;
    padding-top: 1rem;
  }
`

const GraphContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100%;
    height: auto;
    max-width: 28rem;
  }
`

type UseSectionProps = {

}
export const UseSection: FunctionComponent<UseSectionProps> = () => {
  return <Outer>
    <Bg>
      <AssetImage src="use-01.png" />
    </Bg>
    <Inner>
      <TextContainer>
        <Text1>
          お肌のことを第一に考えた<br />
          製品づくり
        </Text1>
        <Text2>
          SKN REMED(スキンリメド)は<br />
          全製品パッチテストもしくはスティンギングテスト済み<sup>※</sup>です
        </Text2>
        <Text3>
          ※ すべての人に皮膚刺激が発生しないということではありません。
        </Text3>
      </TextContainer>
      <GraphContainer>
        <SvgSetGraph02 />
      </GraphContainer>
    </Inner>
  </Outer>
}