import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionHead } from '../components/section-header'
import { AssetImage } from '../components/image'
import { pageMaxWidth } from '../styles/page-max-width'
import { Animate } from '../components/animate'
import { AnimateArrow } from '../components/animate-arrow'
import SvgThinRightArrow from '../generated/svg/thin-right-arrow'
import { useSettings } from '../hooks/use-settings'

const Outer = styled.div`
  ${pageMaxWidth}
`

const Inner = styled.div`
  margin-left: 5rem;
  display: flex;
  gap: 4rem;
  margin-top: 3rem;
  @media (max-width: 40rem){
    margin-left: 0;
    flex-direction: column;
    margin-top: 1.5rem;
    gap: 2.5rem;
  }
`

const TextContainer = styled.div`
  flex: 0 0 max-content;
  display: flex;
  flex-direction: column;
`

const Text1 = styled.div`
  font-size: 1.375rem;
  line-height: 1.7272727272727273;
  letter-spacing: 0.08em;
  font-weight: 700;
  @media (max-width: 40rem){
    font-size: 1.125rem;
    line-height: 1.5555555555555556;
    letter-spacing: 0.08em;
  }
`

const Text2 = styled.div`
  font-size: 0.875rem;
  line-height: 2.4;
  margin-top: 1.5rem;
  @media (max-width: 40rem){
    font-size: 0.875rem;
    line-height: 2.2857142857142856;
    margin-top: 2rem;
  }
`

const ImageContainer = styled.div`
  @media (max-width: 40rem){
    margin-right: calc(var(--page-margin) * -1);
  }
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

const ViewMoreButton = styled.a`
  font-size: 0.875rem;
  line-height: normal;
  letter-spacing: 0.08em;
  width: fit-content;
  display: flex;
  gap: 2rem;
  margin-top: 2rem;
  align-items: baseline;
  transition: opacity 0.4s 0.2s;
  opacity: 0;
  .animate & {
    opacity: 1;
  }
  @media (max-width: 40rem){
    font-size: 0.8125rem;
    letter-spacing: 0.08em;
    margin-top: 2rem;
  }
  svg {
    display: block;
    height: 0.5rem;
    width: auto;
    transition: transform 0.4s 0.4s;
    transform: translateX(-1rem);
    .animate & {
      transform: none;
    }
  }
  &:hover svg {
    transition-delay: 0s;
    transform: translateX(0.5rem);
  }
`

type TopHowToSectionProps = {

}
export const TopHowToSection: FunctionComponent<TopHowToSectionProps> = () => {
  const {themeFileRootPath} = useSettings()
  return <Outer>
    <SectionHead>
      How To
    </SectionHead>
    <Inner>
      <TextContainer>
        <Text1>
          SKN REMED(スキンリメド)の<br />
          ご使用について
        </Text1>
        <Text2>
          キー成分の濃度を高く配合した処方になっております。<br />
          ご使用方法・回数・量をしっかり確認し、<br />
          お肌の様子をみながらご使用ください。<br />
          <br />
          お買い上げの方全員に<br />
          ご使用方法や注意点について記載した<br />
          HOWTO リーフレットもお届けします。
        </Text2>
        <Animate target="child">
          <ViewMoreButton href={`${themeFileRootPath}/assets/howto.pdf`} target="_blank">
            VIEW MORE
            <AnimateArrow>
              <SvgThinRightArrow />
            </AnimateArrow>
          </ViewMoreButton>
        </Animate>
      </TextContainer>
      <ImageContainer>
        <AssetImage src="top-10.png" />
      </ImageContainer>
    </Inner>

  </Outer>
}