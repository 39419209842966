import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import SvgClose from '../generated/svg/close'
import classNames from 'classnames'
import { Popup } from './popup'
import { isNullish } from '../utils/type-check'
import { useModal } from '../hooks/use-modal'
import { marked } from '../styles/marked'

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: stretch;
  z-index: 100;
  transition: opacity 0.4s, transform 0.4s;
  &.hide {
    pointer-events: none;
  }
`

const Bg = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #bababa44;
  transition: backdrop-filter 0.4s, background-color 0.4s;
  backdrop-filter: blur(0.5rem);
  ${Outer}.hide &{
    backdrop-filter: none;
    background-color: transparent;
  }
`

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #bababa44;
  transition: backdrop-filter 0.4s, background-color 0.4s;
  backdrop-filter: blur(0.5rem);
  pointer-events: none;
  &.hide {
    backdrop-filter: none;
    background-color: transparent;
  }
`

const Inner = styled.div`
  background-color: #ECECECfe;
  position: relative;
  padding: 3rem;
  transition: transform 0.4s;
  max-width: 30rem;
  overflow-y: scroll;
  ${Outer}.hide & {
    transform: translateX(100%);
  }
`

const Brand = styled.div`
  font-size: 0.75rem;
  line-height: normal;
  letter-spacing: 0.06em;
  font-family: var(--en-font);
`

const Name = styled.div`
  font-family: var(--en-font);
  font-size: 1.375rem;
  line-height: normal;
  letter-spacing: 0.06em;
  font-weight: 700;
  margin-top: 0.75rem;
`

const NameJa = styled.div`
  font-size: 0.875rem;
  line-height: normal;
  font-weight: 700;
  margin-top: 0.5rem;
`

const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 2rem;
`

const RadioOuter = styled.div`
  cursor: pointer;
  user-select: none;
  border-radius: 0.5rem;
  padding: 1rem 2rem;
  background-color: white;
  transition: border-color 0.4s;
  border: 0.25rem solid transparent;
  position: relative;
  &.active {
  }
  &::after {
    --offset: 0.75rem;
    content: '';
    display: block;
    position: absolute;
    top: calc(var(--offset) * -1);
    left: calc(var(--offset) * -1);
    width: calc(100% + var(--offset) * 2);
    height: calc(100% + var(--offset) * 2);
    border: 0.125rem solid transparent;
    border-radius: 0.75rem;
    box-sizing: border-box;
  }
  &.active::after {
    border-color: #D9D1BE;
  }
`

const RadioLabel = styled.div`
  font-size: 0.8125rem;
  line-height: 1.3846153846153846;
  font-weight: 700;
`

const PriceContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  width: fit-content;
  gap: 0.75rem;
  margin-top: 0.5rem;
`

const PriceItem = styled.div`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span 2;
`

const Price = styled.div`
  line-height: 1.2;
  font-weight: 700;
  font-size: 1rem;
  span {
    font-size: 0.625rem;
    font-weight: 500;
  }
  small {
    font-size: 0.75rem;
    font-weight: 500;
  }
`

const PriceDiscount = styled(Price)`
  color: #A91D2E;
  transform: translateY(-25%);
`

const Note = styled.div`
  font-size: 0.75rem;
  line-height: normal;
  margin-top: 1rem;
  margin-left: -1em;
  ${marked}
`

const CloseButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  cursor: pointer;
  svg {
    display: block;
    height: 1.25rem;
    width: auto;
  }
`

const BuyButton = styled.div`
  background-color: #D9D1BE;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: var(--en-font);
  font-weight: 700;
  text-align: center;
  padding: 0.75rem 0;
  border-radius: 10rem;
  margin-top: 2rem;
  cursor: pointer;
`

const NoticeButton = styled.div`
  margin-top: 2rem;
  font-size: 0.8125rem;
  text-decoration: underline;
  cursor: pointer;
`

const Head = styled.div`
  font-weight: 700;
  line-height: 1.25;
  font-size: 1.25rem;
`

const Text = styled.div`
  margin-top: 1rem;
  font-size: 0.875rem;
  line-height: 2;
  li {
    margin-left: 1em;
    position: relative;
    &::before {
      content: '・';
      position: absolute;
      right: 100%;
      top: 0;
    }
  }
`

const AgreementContainer = styled.label`
  display: block;
  display: flex;
  padding: 1rem 0;
  margin-top: 1rem;
`

const AgreementCheckbox = styled.input``

const AgreementLabel = styled.div`
  font-weight: 700;
`

const AgreementButton = styled.div`
  background-color: #D9D1BE;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: var(--en-font);
  font-weight: 700;
  text-align: center;
  padding: 0.75rem 0;
  border-radius: 10rem;
  margin-top: 1rem;
  cursor: pointer;
  transition: opacity 0.4s;
  &.disabled {
    pointer-events: none;
    opacity: 0.25;
  }
`

const SubscriptionNotice = styled.div`
  font-size: 0.75rem;
  line-height: normal;
  margin-top: 1.5rem;
  ${marked}
`

type CheckoutPopupData = {
  brandName: ReactNode,
  name: ReactNode,
  nameJa: ReactNode,
  items: {
    id: number | string,
    element: ReactNode
  }[]
  note: ReactNode,
  notice?: ReactNode,
  agreement?: ReactNode,
  subscriptionNotice?: ReactNode,
  buttonLabel?: ReactNode
}
type CheckoutPopupProps = {
  open: boolean
  onClose: () => void
  onClick: (id: number | string) => void | Promise<void>
  data: CheckoutPopupData
}
export const CheckoutPopup: FunctionComponent<CheckoutPopupProps> = ({open, onClose, data, onClick}) => {

  const [activeId, setactiveId] = useState(data.items[0]?.id)
  const [sending, setSending] = useState(false)
  const {open: openModal} = useModal()
  useEffect(() => {
    if(isNullish(activeId)){
      setactiveId(data.items[0]?.id)
    }
  }, [data.items])
  return <Outer className={classNames({hide: !open})}>
    <Bg onClick={onClose}/>
    <Inner>
      <Brand>{data.brandName}</Brand>
      <Name>{data.name}</Name>
      <NameJa>{data.nameJa}</NameJa>
      <Note>{data.note}</Note>
      <RadioContainer>
        {
          data.items.map(item => {
            return <RadioOuter key={item.id} className={classNames({active: item.id === activeId})} onClick={() => setactiveId(item.id)}>
              {item.element}
            </RadioOuter>
          })
        }
      </RadioContainer>
      {
        data.subscriptionNotice && <SubscriptionNotice>
          {data.subscriptionNotice}
        </SubscriptionNotice>
      }
      <BuyButton onClick={async () => {
        setSending(true)
        await onClick(data.items.find(item => item.id === activeId)!.id)
        setSending(false)
      }}>{data.buttonLabel ?? 'ADD TO CART'}</BuyButton>
      <CloseButton onClick={onClose}>
        <SvgClose />
      </CloseButton>
    </Inner>
    <Overlay className={classNames({hide: !sending})} />
  </Outer>
}

type AgreementPopupProps = {
  children?: ReactNode
  onClose?: () => void
  onAgree?: () => void
}
const AgreementPopup: FunctionComponent<AgreementPopupProps> = ({children, onClose, onAgree}) => {
  const [checked, setChecked] = useState(false)
  return <Popup onClose={onClose}>
    <Head>ご購入前の同意事項</Head>
    <Text>
      {children}
    </Text>
    <AgreementContainer>
      <AgreementCheckbox type="checkbox" onChange={e => setChecked(e.currentTarget.checked)}/>
      <AgreementLabel>上記を読んだ上で同意する</AgreementLabel>
    </AgreementContainer>
    <AgreementButton className={classNames({disabled: !checked})} onClick={onAgree}>カートに入れる</AgreementButton>
  </Popup>
}

type ProductCheckoutPopupData = {
  brandName: ReactNode,
  name: ReactNode,
  nameJa: ReactNode,
  items: {
    id: string
    label: ReactNode,
    prices: {
      price: ReactNode,
      discount?: ReactNode
    }[]
  }[],
  note: ReactNode,
  notice?: ReactNode,
  agreement?: ReactNode,
  subscriptionNotice?: ReactNode
}

type ProductCheckoutPopupProps = {
  open: boolean
  onClose: () => void
  data: ProductCheckoutPopupData
}
export const ProductCheckoutPopup: FunctionComponent<ProductCheckoutPopupProps> = ({open, onClose, data}) => {
  const [activeId, setactiveId] = useState(data.items[0]?.id)
  const [sending, setSending] = useState(false)
  const {open: openModal} = useModal()
  useEffect(() => {
    if(isNullish(activeId)){
      setactiveId(data.items[0]?.id)
    }
  }, [data.items])
  const addToCart = async (productId?: string) => {
    if(data.notice && !await openModal<boolean>(({resolve}) => {
      return <Popup onClose={() => resolve(false)}>
        <Head>ご購入前の注意事項</Head>
        <Text>
          {data.notice}
        </Text>
        <AgreementButton onClick={() => resolve(true)}>{data.agreement ? '次へ' : '注意事項を確認してカートに入れる'}</AgreementButton>
      </Popup>
    })) return
    if(data.agreement && !await openModal<boolean>(({resolve}) => {
      return <AgreementPopup onAgree={() => resolve(true)} onClose={() => resolve(false)}>
        {data.agreement}
      </AgreementPopup>
    })) return

    setSending(true)
    const formData = new FormData()
    const csrfParamElement = document.querySelector<HTMLMetaElement>('meta[name="csrf-param"]')
    const csrfTokenElement = document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]')
    if(csrfParamElement && csrfTokenElement && productId){
      formData.append(csrfParamElement.content, csrfTokenElement.content)
      formData.append('variant_id', productId.toString())
      formData.append('quantity', '1')
      const res = await fetch('/shop/cart/add', {
        method: 'post',
        body: formData
      })
      location.href = res.url
    } else {
      alert('エラーが発生しました。')
      setSending(false)
    }
  }
  return <Outer className={classNames({hide: !open})}>
    <Bg onClick={onClose}/>
    <Inner>
      <Brand>{data.brandName}</Brand>
      <Name>{data.name}</Name>
      <NameJa>{data.nameJa}</NameJa>
      <Note>{data.note}</Note>
      <RadioContainer>
        {
          data.items.map(item => {
            return <RadioOuter key={item.id} className={classNames({active: item.id === activeId})} onClick={() => setactiveId(item.id)}>
              <RadioLabel>
                {item.label}
              </RadioLabel>
              <PriceContainer>
                {
                  item.prices.map((price, i) => {
                    return <PriceItem key={i}>
                    <Price>{price.price}</Price>
                      {
                        price.discount && <PriceDiscount>
                          {price.discount}
                        </PriceDiscount>
                      }
                    </PriceItem>
                  })
                }
              </PriceContainer>
            </RadioOuter>
          })
        }
      </RadioContainer>
      {
        data.subscriptionNotice && 
        <NoticeButton onClick={() => openModal(({resolve}) => <Popup onClose={resolve}>{data.subscriptionNotice}</Popup>)}>
          定期コースについて
        </NoticeButton>
      }
      <BuyButton onClick={() => addToCart(data.items.find(i => i.id === activeId)?.id)}>ADD TO CART</BuyButton>
      <CloseButton onClick={onClose}>
        <SvgClose />
      </CloseButton>
    </Inner>
    <Overlay className={classNames({hide: !sending})} />
  </Outer>
}
