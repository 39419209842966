import * as React from "react";
import type { SVGProps } from "react";
const SvgSun = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      fill="currentcolor"
      d="M10.988 15.01c2.226 0 4.03-1.796 4.03-4.01s-1.805-4.01-4.03-4.01-4.03 1.796-4.03 4.01 1.805 4.01 4.03 4.01M11.405.928c0-.22-.196-.416-.417-.416s-.418.196-.418.416v3.985c.27 0 .565-.025.835 0zM6.27 2.077c-.122-.196-.368-.27-.565-.147-.196.122-.27.367-.147.562l2.014 3.472c.222-.147.467-.294.713-.416L6.27 2.101zM19.98 6.306c.197-.122.27-.367.148-.562s-.369-.269-.565-.147l-3.49 2.005c.148.22.296.464.418.709l3.465-2.005zM2.02 15.694s-.172.146-.196.244 0 .22.049.318a.4.4 0 0 0 .246.196h.098c.074 0 .147 0 .22-.05l3.49-2.004a7 7 0 0 1-.418-.709l-3.464 2.005zM15.706 19.948s.147.17.246.195h.098c.073 0 .147 0 .22-.049.197-.122.271-.366.148-.562l-2.014-3.471a7 7 0 0 1-.713.415l2.015 3.447zM.865 10.609a.43.43 0 0 0-.418.415c0 .196.123.343.295.392h4.127c0-.27-.024-.563 0-.832H.865zM10.593 21.097c0 .195.123.342.32.39h.098c.123 0 .221-.048.295-.121s.123-.196.123-.294v-3.985c-.27 0-.565.025-.836 0zM5.902 7.626l-3.49-2.004s-.196-.074-.319-.05a.4.4 0 0 0-.245.196c-.05.098-.074.196-.05.318a.4.4 0 0 0 .197.245l3.464 1.98s.05-.122.074-.171c.098-.196.246-.367.369-.538zM14.402 5.94l2.015-3.472s.074-.196.05-.318a.4.4 0 0 0-.197-.244.47.47 0 0 0-.32-.05.4.4 0 0 0-.245.196L13.715 5.5s.122.05.172.074c.196.097.368.244.54.366zM7.596 16.085 5.58 19.556c-.123.196-.049.44.148.563.024 0 .073.024.098.049h.098a.43.43 0 0 0 .369-.196l1.99-3.447s-.123-.049-.172-.073c-.197-.098-.369-.245-.54-.367zM21.135 10.584H17.13c0 .27.025.563 0 .832h4.005c.221 0 .418-.196.418-.416s-.197-.416-.418-.416M16.097 14.398l3.49 2.005s.073.024.098.024h.098a.43.43 0 0 0 .368-.195c.123-.196.05-.44-.147-.563l-3.464-1.98s-.05.122-.074.171c-.098.196-.246.367-.369.538"
    />
  </svg>
);
export default SvgSun;
