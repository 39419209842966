import { useEffect, useState } from 'react'
import { isNotNullish } from '../utils/type-check'
import { ProductData, getProductData } from '../utils/get-product-data'

export const useProductData = (sku: string | null | undefined) => {
  const [data, setData] = useState<ProductData>()
  useEffect(() => {
    if(isNotNullish(sku)){
      getProductData(sku).then(data => setData(data))
    }
  }, [])
  return data
}