import React, { Fragment, FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { AssetImage } from './image'
import { DetailTable } from './detail-table'
import { Variations } from './variations'
import { Animate } from './animate'

const Outer = styled.div`
`

const Inner = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 6fr 4fr;
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
    gap: 3rem;
  }
`


const ImageContainer = styled.div`
  opacity: 0;
  transform: translateY(2rem);
  transition: opacity, transform;
  position: relative;
  min-width: 0;
  
  @media (max-width: 40rem){
    min-height: 16rem;
  }
  .animate & {
    opacity: 1;
    transform: none;
    transition: 1.2s 0.2s, 1.2s 0.2s;
  }
  img {
    display: block;
    width: 40rem;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 60rem){
      width: 34rem;
    }
    @media (max-width: 40rem){
    }
  }
`

const TextContainer = styled.div`
  position: relative;
  z-index: 2;
  min-width: 0;
  overflow-x: clip;
  @media (max-width: 40rem){
    align-self: center;
    justify-self: center;
    width: fit-content;
  }
`

const NameRow = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
  @media (max-width: 40rem){
    flex-direction: column;
    align-items: flex-start;
    gap: 2rem;
  }
`

const Name = styled.div`
  font-weight: 700;
`


const Info1Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  width: fit-content;
  column-gap: 2rem;
  margin-top: 2rem;
`

const Info1Item = styled.div`
  font-size: 0.875rem;
  line-height: 2;
`

const TagContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  margin-top: 0.5rem;
`

const TagItem = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  padding: 0 1rem;
  background-color: var(--color);
  color: white;
`

const TagsNote = styled.div``

const Detail = styled.div`
  padding: 1rem 1.5rem;
  font-size: 0.875rem;
  line-height: 2;
  background-color: var(--color);
  margin-top: 1.5rem;
`

const Info2Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  width: fit-content;
  column-gap: 2rem;
  row-gap: 0.5rem;
  margin-top: 2rem;
`

const Info2Item = styled.div`
  font-size: 0.75rem;
  line-height: 1.6666666666666667;

`

const DetailTableContainer = styled.div`
  margin-top: 3rem;
`

const DetailNote = styled.div`
  font-size: 0.5625rem;
  line-height: normal;
  margin-top: 0.5rem;
  text-align: right;
`

const Note = styled.div`
  font-size: 0.875rem;
  line-height: 1.5;
  margin-top: 1rem;
  margin-bottom: -0.5rem;
`

type Info = {
  label: ReactNode,
  value: ReactNode
}

type ProductDetailData = {
  image: string,
  name: ReactNode,
  variations: {
    label: ReactNode,
    color: string,
    active?: boolean,
    link: string
  }[],
  info1: Info[],
  info2: Info[],
  info3: Info[],
  tags: {
    label: ReactNode,
    color: string
  }[],
  tagsNote?: ReactNode,
  detail: ReactNode,
  detailNote?: ReactNode,
  detailBackground: string,
  note?: ReactNode
}

type ProductDetail1Props = {
  data: ProductDetailData
}
export const ProductDetail1: FunctionComponent<ProductDetail1Props> = ({data}) => {
  return <Outer>
    <Inner>
      <Animate target="child">
        <ImageContainer>
            <AssetImage src={data.image} />
        </ImageContainer>
      </Animate>
      <TextContainer>
        <NameRow>
          <Name>
            {data.name}
          </Name>
          <Variations items={data.variations} />
        </NameRow>
        {
          data.note && <Note>
            {data.note}
          </Note>
        }
        <Info1Container>
          {
            data.info1.map((row, i) => <Fragment key={i}>
              <Info1Item>{row.label}</Info1Item>
              <Info1Item>{row.value}</Info1Item>
            </Fragment>)
          }
        </Info1Container>
        <TagContainer>
          {data.tags.map((t, i) => <TagItem key={i} style={{'--color': t.color}}>{t.label}</TagItem>)}
        </TagContainer>
        {
          data.tagsNote && <TagsNote>
            {data.tagsNote}
          </TagsNote>
        }
        <Detail style={{'--color': data.detailBackground}}>
          {data.detail}
        </Detail>
        {
          data.detailNote && <DetailNote>{data.detailNote}</DetailNote>
        }
        <Info2Container>
          {
            data.info2.map((row, i) => <Fragment key={i}>
              <Info2Item>{row.label}</Info2Item>
              <Info2Item>{row.value}</Info2Item>
            </Fragment>)
          }
        </Info2Container>
        <DetailTableContainer>
          <DetailTable
            items={data.info3}
          />
        </DetailTableContainer>
      </TextContainer>
    </Inner>
  </Outer>
}