import { styled } from 'styled-components'

export const BoxText = styled.div`
  display: inline-block;
  font-size: 0.7em;
  padding: 0.1em 0.25em 0.2em;
  border: 1px solid currentColor;
  margin-left: 0.5rem;
  line-height: 1;
  vertical-align: middle;
  transform: translateY(-0.2em);
`