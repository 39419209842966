import React, { FunctionComponent, ReactNode, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import classNames from 'classnames'

const Outer = styled.div`
  display: contents;
`

type AnimateProps = {
  children?: ReactNode,
  target?: 'parent' | 'child'
}
export const Animate: FunctionComponent<AnimateProps> = ({children, target}) => {
  const outerRef = useRef<HTMLDivElement | null>(null)
  const [animate, setAnimate] = useState(false)
  useEffect(() => {
    const listener = () => {
      const outer = outerRef.current
      const targetElement = target === 'child' ? outer?.children[0] : outer?.parentElement
      if(outer && targetElement){
        const clientRect = targetElement.getBoundingClientRect()
        setAnimate(clientRect.top < window.innerHeight)
      }
    }
    window.addEventListener('scroll', listener)
    window.addEventListener('resize', listener)
    listener()
    return () => {
      window.removeEventListener('scroll', listener)
      window.removeEventListener('resize', listener)
    }
  }, [target])
  return <Outer ref={outerRef} className={classNames({animate})}>
    {children}
  </Outer>
}