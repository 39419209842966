import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { Animate } from './animate'

const Outer = styled.div`
  display: contents;
  svg > g:nth-of-type(even) {
    opacity: 0;
    transition: opacity 1s;
    transition-delay: 0;
    &:nth-of-type(1) {
      --index: 1;
    }
    &:nth-of-type(2) {
      --index: 2;
    }
    &:nth-of-type(3) {
      --index: 3;
    }
    &:nth-of-type(4) {
      --index: 4;
    }
    &:nth-of-type(5) {
      --index: 5;
    }
    &:nth-of-type(6) {
      --index: 6;
    }
    &:nth-of-type(7) {
      --index: 7;
    }
    &:nth-of-type(8) {
      --index: 8;
    }
  }
  .animate svg g:nth-of-type(even) {
    opacity: 1;
    transition-delay: calc(0.4s + 0.1s * var(--index) + var(--delay));
  }
  .swiper-slide & .animate svg g:nth-of-type(even) {
    opacity: 0;
  }
  .swiper-slide.swiper-slide-active & .animate svg g:nth-of-type(even) {
    opacity: 1;
  }
`

type AnimateStepProps = {
  children?: ReactNode
  delay?: string
}
export const AnimateStep: FunctionComponent<AnimateStepProps> = ({children, delay}) => {
  return <Outer style={{'--delay': delay ?? '0s'}}>
    <Animate target="child">
      {children}
    </Animate>
  </Outer>
}