import React, { FunctionComponent, ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { useSettings } from '../hooks/use-settings'

const Outer = styled.div`
  padding-top: calc(8rem + var(--header-notice-height, 0rem));
`

const Notice = styled.div`
  position: fixed;
  top: var(--hide-header-height);
  transition: top 0.4s;
  padding-inline: var(--page-margin);
  padding-block: 0.75rem;
  line-height: 1.25rem;
  background-color: rgb(233 73 73);
  color: white;
  font-weight: 700;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
  font-size: 0.85rem;
  z-index: 99;
  @media (max-width: 40rem){
    text-align: left;
  }
  small {
    font-size: 0.85em;
  }
`

type ProductPageComponentsProps = {
  children?: ReactNode
}
export const ProductPageOuter: FunctionComponent<ProductPageComponentsProps> = ({children}) => {
  const outerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const outer = outerRef.current
    if(outer){
      const listener = () => {
        const {height} = outer.getBoundingClientRect()
        document.body.style.setProperty('--header-notice-height', `${height}px`)
      }
      const observer = new ResizeObserver(listener)
      observer.observe(outer)
      listener()
      return () => {
        observer.disconnect()
      }
    }
  }, [])
  return <>
    {/* {
      <Notice ref={outerRef}>
        大好評につき完売となったため現在予約販売中です。<br />
        今月の出荷分は9/23（月）より順次発送予定となります。<br />
        <small>※定期で既に申し込んでいる皆様のお届けは通常のスケジュールでお届けします。</small>
      </Notice>
    } */}
    <Outer>
      {children}
    </Outer>
  </>
}