import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { Swiper, SwiperSlide } from 'swiper/react'
import SvgSun from '../generated/svg/sun'
import SvgMoon from '../generated/svg/moon'
import { AssetImage } from '../components/image'
import { pageMaxWidth } from '../styles/page-max-width'
import { slideWidth } from '../styles/slide-width'

const Outer = styled.div`
  overflow-x: hidden;
`

const Inner = styled.div`
  ${pageMaxWidth}
`

const Text1 = styled.div`
  font-size: 1.875rem;
  line-height: normal;
  letter-spacing: 0.06em;
  font-weight: 500;
`

const Text2 = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  margin-top: 1.5rem;
  small {
    font-size: 0.8em;
  }
`

const ItemContainer = styled.div`
  --gap: 2rem;
  --slides-per-view: 3;
  @media (max-width: 40rem){
    --slides-per-view: 1;
  --gap: 1rem;
  }
  .swiper {
    overflow: visible;
  }
  .swiper-wrapper {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-auto-rows: auto;
    @media (max-width: 40rem){
      grid-auto-columns: calc(100% + var(--gap));
    }
  }
  .swiper-slide {
    display: grid;
    grid-row: span 6;
    grid-template-rows: subgrid;
  }
`

const ItemOuter = styled.div`
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 6;
  margin-right: var(--gap);
`

const ImageContainer = styled.div`
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  img {
    display: block;
    width: 6.5rem;
    height: auto;
    filter: drop-shadow(0.3125rem 0.4375rem 0.375rem #26282932);
  }
`

const NameRow = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: space-between;
`

const Name = styled.div`
  font-size: 1.375rem;
  line-height: normal;
  font-family: var(--en-font);
  font-weight: 700;
`

const IconContainer = styled.div`
  display: flex;
  gap: 0.25rem;
  align-items: flex-end;
  span {
    display: block;
    margin-bottom: 0.125rem;
    font-weight: 700;
  }
`

const Icon = styled.div`
  svg {
    display: block;
    width: 1.375rem;
    height: auto;
  }
`

const ItemText = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  margin-top: 1.5rem;
`

const List = styled.div`
  margin-top: 1.5rem;
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 6;
`

const ListItem = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  padding: 1.5rem 0;
  border-top: 1px solid currentColor;
`

const ListItemLabel = styled.div`

`

const ListItemValueContainer = styled.div`

`

const ListItemValue = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  &::before {
    content: '・';
  }
`

const Text3 = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  margin-top: 3rem;
`

const Caution = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  padding: 1.5rem;
  text-align: center;
  background-color: #CC4444;
  color: white;
  margin-top: 1.5rem;
  font-weight: 700;
  border-radius: 0.5rem;
`

const Note = styled.div`
  font-size: 0.875rem;
  line-height: normal;
  margin-top: 3rem;
  text-align: right;
`

export type ProductVariationItem = {
  img: string,
  name: ReactNode,
  text: ReactNode,
  list: {
    label: ReactNode,
    items: ReactNode[]
  }[],
  night?: boolean,
  day?: boolean,
  color: string
}

export type ProductVariationSectionData = {
  items: ProductVariationItem[],
  text1: ReactNode,
  text2: ReactNode,
  text3: ReactNode,
  note?: ReactNode,
  caution?: ReactNode
}

type ProductVariationSectionProps = {
  data: ProductVariationSectionData
}
export const ProductVariationSection: FunctionComponent<ProductVariationSectionProps> = ({data}) => {
  return <Outer>
    <Inner>
      <Text1>{data.text1}</Text1>
      <Text2>{data.text2}</Text2>
      <ItemContainer>
        <Swiper
          slidesPerView="auto"
        >
          {
            data.items.map((item, i) => {
              return <SwiperSlide key={i}>
                <ItemOuter>
                  <ImageContainer>
                    <AssetImage src={item.img} />
                  </ImageContainer>
                  <NameRow>
                    <Name style={{color: item.color}}>
                      {item.name}
                    </Name>
                    <IconContainer style={{color: item.color}}>
                      {
                        item.day && <Icon>
                          <SvgSun />
                        </Icon>
                      }
                      {
                        item.day && item.night && <span>OR</span>
                      }
                      {
                        item.night && <Icon>
                          <SvgMoon />
                        </Icon>
                      }
                    </IconContainer>
                  </NameRow>
                  <ItemText>
                    {item.text}
                  </ItemText>
                  <List>
                    {
                      item.list.map((row, i) => {
                        return <ListItem>
                          <ListItemLabel>{row.label}</ListItemLabel>
                          <ListItemValueContainer>
                            {row.items.map((item, i) => <ListItemValue key={i}>{item}</ListItemValue>)}
                          </ListItemValueContainer>
                        </ListItem>
                      })
                    }
                  </List>
                </ItemOuter>
              </SwiperSlide>
            })
          }
        </Swiper>
      </ItemContainer>
      <Text3>
        {data.text3}
      </Text3>
      {
        data.caution && <Caution>
          {data.caution}
        </Caution>
      }
      {
        data.note && <Note>
          {data.note}
        </Note>
      }
    </Inner>
  </Outer>
}