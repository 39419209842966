import * as React from "react";
import type { SVGProps } from "react";
const SvgMoon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      fill="currentcolor"
      d="M17.108 3.363a9.77 9.77 0 0 0-5.086-2.2 8.8 8.8 0 0 1 2.138 1.295 8.683 8.683 0 0 1 1.13 12.248c-3.096 3.692-8.6 4.18-12.31 1.125a8 8 0 0 1-1.67-1.858 9.9 9.9 0 0 0 3.095 4.571c4.227 3.496 10.492 2.934 13.98-1.27 3.514-4.206 2.95-10.44-1.277-13.911"
    />
  </svg>
);
export default SvgMoon;
