import React, { ComponentProps, FunctionComponent, ReactPropTypes } from 'react'
import styled from 'styled-components'
import { TopHowToSection } from './top-how-to-section'
import { UsageGuide } from '../components/usage-guide'
import { pageMaxWidthWithPadding } from '../styles/page-max-width'

const Outer = styled.div``

const GuideContainer = styled.div`
  ${pageMaxWidthWithPadding}
  margin-top: 6rem;
`



type HowToSectionProps = {
  use: ComponentProps<typeof UsageGuide>['use']
  initial: ComponentProps<typeof UsageGuide>['initial']
}
export const HowToSection: FunctionComponent<HowToSectionProps> = ({use, initial}) => {
  return <Outer>
    <TopHowToSection />
    <GuideContainer>
      <UsageGuide use={use} initial={initial}/>
    </GuideContainer>
  </Outer>
}