import * as React from "react";
import type { SVGProps } from "react";
const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 14 14"
    {...props}
  >
    <path fill="currentcolor" d="M14 6.588v.824H0v-.824z" />
    <path fill="currentcolor" d="M6.588 0h.823v14h-.823z" />
  </svg>
);
export default SvgPlus;
