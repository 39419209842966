import * as React from "react";
import type { SVGProps } from "react";
const SvgSetGraphB05 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 101.731 102.192"
    {...props}
  >
    <defs>
      <clipPath id="d8649a956718c76d5d16ab617615b02c8bee9ee4-a">
        <path
          fill="none"
          d="M36.206 7.502c-15.81 30.673-10.286 66.01.472 88.734 0 0 1.622 3.891 6.042 5.036a51 51 0 0 0 8.146.661c27.948 0 50.615-22.76 50.615-50.846S78.823.25 50.866.25a52 52 0 0 0-6.431.419c-1.418.251-5.45 1.424-8.238 6.823zM46.25 6.469c2.595-.298 4.476-.215 7.182-.038C76.238 7.874 94.04 26.092 95.087 49.03c1.167 25.693-19.22 46.89-44.536 46.89-1.075 0-2.15-.038-3.206-.112a7.9 7.9 0 0 1-6.802-5.046c-9.953-26.047-9.786-53.974.648-79.983.677-1.844 3.114-4.078 5.06-4.301z"
        />
      </clipPath>
      <clipPath id="d8649a956718c76d5d16ab617615b02c8bee9ee4-b">
        <path
          fill="none"
          d="M53.433 6.431c-2.706-.167-4.587-.251-7.182.037-1.946.224-4.383 2.458-5.06 4.301-10.434 26.01-10.592 53.928-.649 79.984a7.9 7.9 0 0 0 6.802 5.045 46 46 0 0 0 3.207.112c25.316 0 45.703-21.197 44.535-46.89C94.04 26.083 76.247 7.865 53.433 6.422z"
        />
      </clipPath>
    </defs>
    <path
      fill="#fff"
      stroke="#ee958f"
      strokeMiterlimit={10}
      strokeWidth={0.5}
      d="M50.865 101.942c27.953 0 50.615-22.764 50.615-50.846S78.818.25 50.865.25.249 23.015.249 51.096s22.662 50.846 50.616 50.846Z"
    />
    <path
      fill="none"
      stroke="#ee958f"
      strokeMiterlimit={10}
      strokeWidth={0.5}
      d="M44.952.604s-5.32.242-8.748 6.888c-15.81 30.674-10.286 66.01.472 88.734 0 0 2.132 5.111 8.164 5.344"
    />
    <g fill="none" clipPath="url(#d8649a956718c76d5d16ab617615b02c8bee9ee4-a)">
      <g stroke="#ee958f" strokeMiterlimit={10} strokeWidth={0.5}>
        <path d="m27.217-1.91-46.473 108.814M30.515-1.91l-46.482 108.814M33.805-1.91l-46.473 108.814M37.103-1.91-9.37 106.904M40.404-1.91-6.08 106.904M43.693-1.91-2.78 106.904M46.992-1.91.509 106.904M50.281-1.91 3.808 106.904M53.58-1.91 7.107 106.904M56.88-1.91 10.398 106.904M60.17-1.91 13.697 106.904M63.468-1.91 16.986 106.904M66.757-1.91 20.285 106.904M70.056-1.91 23.584 106.904M73.356-1.91 26.875 106.904M76.636-1.91 30.164 106.904M79.935-1.91 33.453 106.904M83.224-1.91 36.751 106.904M86.524-1.91 40.042 106.904M89.823-1.91 43.341 106.904M93.113-1.91 46.64 106.904M96.411-1.91 49.93 106.904M99.701-1.91 53.228 106.904M103-1.91 56.527 106.904M106.3-1.91 59.818 106.904M109.59-1.91 63.115 106.904M112.888-1.91 66.405 106.904M116.177-1.91 69.704 106.904M119.476-1.91 73.003 106.904M122.776-1.91 76.294 106.904M126.066-1.91 79.593 106.904M129.364-1.91 82.882 106.904M132.663-1.91 86.181 106.904M135.953-1.91 89.48 106.904M139.253-1.91 92.771 106.904M142.542-1.91 96.07 106.904" />
      </g>
    </g>
    <path
      fill="#fff"
      stroke="#ee958f"
      strokeMiterlimit={10}
      strokeWidth={0.5}
      d="M53.433 6.431c-2.706-.167-4.587-.251-7.182.037-1.946.224-4.383 2.458-5.06 4.301-10.434 26.01-10.592 53.928-.649 79.984a7.9 7.9 0 0 0 6.802 5.045 46 46 0 0 0 3.207.112c25.316 0 45.703-21.197 44.535-46.89C94.04 26.083 76.247 7.865 53.433 6.422z"
    />
    <path
      fill="#fff"
      d="M53.433 6.431c-2.706-.167-4.587-.251-7.182.037-1.946.224-4.383 2.458-5.06 4.301-10.434 26.01-10.592 53.928-.649 79.984a7.9 7.9 0 0 0 6.802 5.045 46 46 0 0 0 3.207.112c25.316 0 45.703-21.197 44.535-46.89C94.04 26.083 76.247 7.865 53.433 6.422z"
    />
    <g fill="none" clipPath="url(#d8649a956718c76d5d16ab617615b02c8bee9ee4-b)">
      <path
        fill="rgba(238, 149, 143, .5)"
        stroke="#ee958f"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M50.865 79.126c15.41 0 27.902-12.55 27.902-28.03s-12.493-28.03-27.902-28.03-27.903 12.55-27.903 28.03 12.493 28.03 27.903 28.03Z"
      />
    </g>
    <path
      fill="none"
      stroke="#ee958f"
      strokeMiterlimit={10}
      strokeWidth={0.5}
      d="M53.433 6.431c-2.706-.167-4.587-.251-7.182.037-1.946.224-4.383 2.458-5.06 4.301-10.434 26.01-10.592 53.928-.649 79.984a7.9 7.9 0 0 0 6.802 5.045 46 46 0 0 0 3.207.112c25.316 0 45.703-21.197 44.535-46.89C94.04 26.083 76.247 7.865 53.433 6.422z"
    />
    <path
      fill="#fff"
      d="M45.835 43.602c4.094 0 7.413-3.334 7.413-7.447s-3.319-7.447-7.413-7.447-7.414 3.334-7.414 7.447 3.32 7.447 7.414 7.447"
    />
  </svg>
);
export default SvgSetGraphB05;
