import React, { FunctionComponent, ReactNode, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { Animate } from './animate'

const Outer = styled.div`
  display: contents;
  svg path {
    transition: stroke-dashoffset;
    stroke-dasharray: var(--length);
    stroke-dashoffset: var(--length);
  }
  & > .animate svg path {
    stroke-dashoffset: 0;
    transition-duration: 1s;
    transition-delay: 0.4s;
  }
`

type AnimateArrowProps = {
  children?: ReactNode
  delay?: string
  selector?: string
}
export const AnimateArrow: FunctionComponent<AnimateArrowProps> = ({children, delay, selector: target}) => {
  const outerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const outer = outerRef.current
    if(outer){
      const paths = outer.querySelectorAll<SVGPathElement>(target ?? 'path')
      paths.forEach(path => {
        path.style.setProperty('--length', path.getTotalLength().toString())
      })
    }
  })
  return <Outer ref={outerRef} style={{'--delay': delay ?? '0s'}}>
    <Animate target="child">
      {children}
    </Animate>
  </Outer>
}