import * as React from "react";
import type { SVGProps } from "react";
const SvgSetGraphB01 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 103.118 100.902"
    {...props}
  >
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M71.393 97.76c-6.16-15.574-18.83-21.198-33.83-27.655-31.39-13.526-26.82-38.219-27.8-43.903 0 0-1-2.27-.03-2.58.97-.312 1.12 1.496 1.14 2.219s-.12 2.59-.12 2.59"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M9.653 35.25c-.22-.27-.97-3.233-1.53-3.806-.56-.572-4.2-2.832-3.16-3.354 1.79-.893 3.16 3.354 3.16 3.354"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M9.653 35.25c-.22-.27-.97-3.233-1.53-3.806m0 0c-.56-.572-4.2-2.832-3.16-3.354 1.79-.893 3.16 3.354 3.16 3.354"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M10.274 37.891s-.08-5.201.16-6.778 1.12-3.796 2.05-2.922c0 0-.07 1.095-.66 2.58-.59 1.477-.53 1.356-.53 1.356"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M10.274 37.891s-.08-5.201.16-6.778 1.12-3.796 2.05-2.922c0 0-.07 1.095-.66 2.58-.59 1.477-.53 1.356-.53 1.356"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M10.713 39.468c-.61-.743-3.17-5.874-4.01-5.081s1.92 4.047 1.92 4.047l2.32 1.737"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M10.713 39.468c-.61-.743-3.17-5.874-4.01-5.081s1.92 4.047 1.92 4.047l2.32 1.737"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M11.623 42.079c.01-.623.7-4.77 1.64-5.152.94-.381-.12 2.611-.41 3.846s-1.23 1.306-1.23 1.306"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M18.323 56.328c-.61-1.034-4.86-2.973-9.55-3.264s-7.14 1.366-7.29 2.913c-.14 1.546 1.93-.312 1.93-.312s2-1.406 2.46-1.657"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M11.764 53.466s-6.36 1.737-6.67 7.37c0 0 .38 3.154 1.51.372s1.12-4.458 1.12-4.458"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M11.764 53.466s-6.36 1.737-6.67 7.37c0 0 .38 3.154 1.51.372s1.12-4.458 1.12-4.458"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M9.504 53.125s-3.33-1.788-3.54-3.435c-.2-1.647 1 0 1.31.322.31.321 1.35 2.239 1.35 2.239"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M9.504 53.125s-3.33-1.788-3.54-3.435c-.2-1.647 1 0 1.31.322.31.321 1.35 2.239 1.35 2.239"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M14.993 54.39c-.12-.04-2.82 1.104-3.56 2.2-.74 1.104 1.25.792 2.11-.091.86-.884 1.57-1.647 1.57-1.647"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M14.993 54.39c-.12-.04-2.82 1.104-3.56 2.2-.74 1.104 1.25.792 2.11-.091.86-.884 1.57-1.647 1.57-1.647M64.34 97.76s-18.2-14.882-25.8-22.664c-12.51-12.813-16.39-26.099-17.8-35.538-1.4-9.36-6.82-31.491-6.82-31.491"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M14.02 7.886s.54-2.22-.13-5.804c-.68-3.595-1.83-.944-1.8-.05S14.2 6.53 14.2 6.53"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M14.02 7.886s.54-2.22-.13-5.804c-.68-3.595-1.83-.944-1.8-.05S14.2 6.53 14.2 6.53"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M14.91 12.144s-.29-1.125 1.31-4.439 2.12-4.298 2.96-3.866-.4 2.37-.65 2.6C18.09 6.852 15.64 9 15.64 9"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M14.91 12.144s-.29-1.125 1.31-4.439 2.12-4.298 2.96-3.866-.4 2.37-.65 2.6C18.09 6.852 15.64 9 15.64 9"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M16.18 18.079s-1.02-3.606 1.56-6.377c1.84-1.978 2.02-1.527 2.56-1.486.18.01.28.2.22.361-.29.763-.36 1.055-1.74 2.16-1.52 1.204-1.7 1.515-1.7 1.515"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M16.18 18.079s-1.02-3.606 1.56-6.377c1.84-1.978 2.02-1.527 2.56-1.486.18.01.28.2.22.361-.29.763-.36 1.055-1.74 2.16-1.52 1.204-1.7 1.515-1.7 1.515"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M13.68 9.904c-.3-.29-2.39-4.76-3.8-4.117s3.64 3.314 4.33 4.84"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M13.68 9.904c-.3-.29-2.39-4.76-3.8-4.117s3.64 3.314 4.33 4.84"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M14.25 14.694c-.01-.24-2.14-5.141-3.86-3.775-1.2.954 2.76 1.998 3.45 3.072s2.76 5.363 2.76 5.363"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M14.25 14.694c-.01-.24-2.14-5.141-3.86-3.775-1.2.954 2.76 1.998 3.45 3.072s2.76 5.363 2.76 5.363"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M19.19 31.143c.03-1.185 1.56-5.433 2.41-6.266 2.25-2.21 3.14-.251-1.04 3.444"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M19.19 31.143c.03-1.185 1.56-5.433 2.41-6.266 2.25-2.21 3.14-.251-1.04 3.444"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M18.76 29.104s-1.97-4.448-4-5c-1.57-.422-2.69 1.034-.84 1.285 1.76.25 2.88 1.175 2.79 1.124"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M18.76 29.104s-1.97-4.448-4-5c-1.57-.422-2.69 1.034-.84 1.285 1.76.25 2.88 1.175 2.79 1.124"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M18.37 32.197c-.09-1.496-2.44-4.91-3.22-3.354-.42.834 1.75 1.828 2.45 2.561s2.42 4.067 2.57 4.72"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M18.37 32.197c-.09-1.496-2.44-4.91-3.22-3.354-.42.834 1.75 1.828 2.45 2.561s2.42 4.067 2.57 4.72M28.77 22.557s-.51-1.486-.17-2.832c.35-1.345.81-.874.89-.361.22 1.365 0 1.797 0 1.797-.42 3.374-5.12 12.14-5.97 15.967s-1.1 10.383-1.01 10.704"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M28.3 25.057c.33-.522 2.01-2.64 2.6-1.717.59.924-2.3 1.677-2.3 1.677"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M28.3 25.057c.33-.522 2.01-2.64 2.6-1.717.59.924-2.3 1.677-2.3 1.677"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M28.24 24.686c-.16-.452-.22-1.546-.82-1.095s.56 1.517.56 1.517"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M28.24 24.686c-.16-.452-.22-1.546-.82-1.095s.56 1.517.56 1.517"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M27.5 27.97c.28-.382 2.28-1.486 2.78-1.115.5.372 0 1.125-.83 1.145s-2.77.934-2.77.934"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M27.5 27.97c.28-.382 2.28-1.486 2.78-1.115.5.372 0 1.125-.83 1.145s-2.77.934-2.77.934"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M25.78 31.022c.01-.773.28-1.877-.25-4.187-.18-.773-1.03-.864-1.04-.241s.58 1.617.73 2.028"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M25.78 31.022c.01-.773.28-1.877-.25-4.187-.18-.773-1.03-.864-1.04-.241s.58 1.617.73 2.028"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M22.71 43.273c.02-.29 2.46-5.412 4.53-5.854s.15.984.15.984l-1.56.803"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M22.71 43.273c.02-.29 2.46-5.412 4.53-5.854s.15.984.15.984l-1.56.803"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M28.04 61.158c-.14-.512-.29-9.801 1.87-12.834s2.02-.883 1.58-.25c-.44.632-1.52 1.586-1.52 1.586"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M28.04 61.158c-.14-.512-.29-9.801 1.87-12.834s2.02-.883 1.58-.25c-.44.632-1.52 1.586-1.52 1.586"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M28.16 55.123c.87-2.29 4.04-3.846 4.04-3.846s1.9-.593.94.492c-.69.783-2.77.934-2.77.934"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M28.16 55.123c.87-2.29 4.04-3.846 4.04-3.846s1.9-.593.94.492c-.69.783-2.77.934-2.77.934"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M23.75 41.165c.18-.302 1.59-.975 1.74-.553.16.422-2.28 1.537-2.28 1.537"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M23.75 41.165c.18-.302 1.59-.975 1.74-.553.16.422-2.28 1.537-2.28 1.537"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M25 39.267c.15-.332.31-1.587.31-2.2s.86-.642.79-.12-.54 1.667-.54 1.667"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M25 39.267c.15-.332.31-1.587.31-2.2s.86-.642.79-.12-.54 1.667-.54 1.667"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M28.66 51.668s-.11-.713-.54-2.068c-.44-1.356-.9-2.38-.52-2.58.72-.373.99 1.987.88 2.258"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M28.66 51.668s-.11-.713-.54-2.068c-.44-1.356-.9-2.38-.52-2.58.72-.373.99 1.987.88 2.258M40.74 77.325c-.28-.28-11.65.512-17.13-7.702s-6.43-4.92-5.92-4.609c1.11.663 2.55 1.125 2.55 1.125"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M22.86 71.17s-1.5.502-2.49-.1-1.51-2.16.23-1.457 5.83 3.133 5.83 3.133c-2.2-1.536-1.8-6.326-1.8-6.326s1.22-2.37.79-.12c-.28 1.465.06 3.584.06 3.584"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M22.86 71.17s-1.5.502-2.49-.1-1.51-2.16.23-1.457 5.83 3.133 5.83 3.133c-2.2-1.536-1.8-6.326-1.8-6.326s1.22-2.37.79-.12c-.28 1.465.06 3.584.06 3.584M39.723 39.397s5.65 15.023 4.21 24.783 2.21 35.649 2.82 36.472"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M47.473 69.804s-.72 1.797-.3 2.28c.42.481 1.23-.392 2.07-1.125.84-.734-.72-1.667-.72-1.667.7-1.286-1.14-.442-.95-.744.19-.3-.37-.391-1.14-.823s-.76 1.346-.89 2.56c-.13 1.216.42 1.668.42 1.668-1.25 2.32-1.81 9.298-1.81 9.298M43.613 68.317s.69-7.18 5.05-10.182c0 0 .65.572 1.7-.432s1.72-1.797 1.54-2.189-1.03-1.657-1.58-1.074c0 0-1.03-.964-1.27-.201s-.63 2.912-.63 2.912M46.083 47.993s.14 2.46-.29 3.795c-.43 1.336-2.04 3.435-2.04 3.435"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M45.503 48.525c-.26-.321-1.01-.542-1.05-1.376s-.77-2.691.21-1.928c0 0 .56-.532 1.26-.18.7.35 1.65-.02 1.56.994-.09 1.004.2 3.093-1.27 2.47"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M41.493 44.699c.04-.803-.09-4.268.48-5.513s1.38-1.988 1.45-1.647.85.673-.13 1.617-.98 1.366-.98 1.366"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M41.493 44.699c.04-.803-.09-4.268.48-5.513s1.38-1.988 1.45-1.647.85.673-.13 1.617-.98 1.366-.98 1.366"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M39.483 38.453c-.52-1.064-1.3-4.75-.94-5.312s1.59-.793 1.02.833c-.57 1.627-.09 1.577-.09 1.577"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M39.483 38.453c-.52-1.064-1.3-4.75-.94-5.312s1.59-.793 1.02.833c-.57 1.627-.09 1.577-.09 1.577"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M39.053 39.256c-.45-.21-2.03-3.334-3.16-3.153s.47 1.858.47 1.858h1.16"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M39.053 39.256c-.45-.21-2.03-3.334-3.16-3.153s.47 1.858.47 1.858h1.16"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M40.653 42.118c-.24-.572-2.02-2.018-2.49-1.637-.47.382-.06.623.27 1.055s2.22.582 2.22.582"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M42.673 49.419c-.06-.221-3-4.248-4.69-5.111-1.31-.673-3.55-1.477-2.57-.121.48.663 2.21.753 2.21.753"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M42.673 49.419c-.06-.221-3-4.248-4.69-5.111-1.31-.673-3.55-1.477-2.57-.121.48.663 2.21.753 2.21.753M38.482 48.355c.42 0 2.01.431 2.91 2.44s1.85 2.29 1.85 2.29"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M38.003 47.883c-.24-.181-1.83-.1-1.55.401.51.914-.16 1.195.24 2.119.24.552 2.06-.05 2.16-.763"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M38.003 47.883c-.24-.181-1.83-.1-1.55.401.51.914-.16 1.195.24 2.119.24.552 2.06-.05 2.16-.763"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M39.753 55.625c-.32-.804-.83-1.838-1.44-1.908s-.7.391-.7.391-.82.191-.8.643-.82.613-.41 1.295c.41.683 1.25 1.316 2.2.673"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M39.753 55.625c-.32-.804-.83-1.838-1.44-1.908s-.7.391-.7.391-.82.191-.8.643-.82.613-.41 1.295c.41.683 1.25 1.316 2.2.673"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M38.003 55.625s.93.502 1.12 1.245 2.14 4.64 5.03 4.86"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M50.033 55.735s-.11 1.496-.86 1.456z"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M50.033 55.735s-.11 1.496-.86 1.456"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M47.793 57.754s-.56-.06-.34-1.346c.22-1.285.43-1.908.53-2.028s.95.32.95.32"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M47.793 57.754s-.56-.06-.34-1.346c.22-1.285.43-1.908.53-2.028s.95.32.95.32M38.773 64.05c.2-.04 4.78 5.302 4.84 7.46"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M38.273 63.387c-.23-.13-1.56-.713-1.74-.08-.18.632.18.692.18.692s-.98 1.025.17 1.276c1.15.25 1.82-.211 1.82-.211"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M38.273 63.387c-.23-.13-1.56-.713-1.74-.08-.18.632.18.692.18.692s-.98 1.025.17 1.276c1.15.25 1.82-.211 1.82-.211"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M37.593 62.443s.25-.783.66-.432 1.64 1.165 1.05 2.109"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M37.593 62.443s.25-.783.66-.432 1.64 1.165 1.05 2.109"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M50.717 75.649c-.12-.191-1.23-2.39-1.69-1.989-.46.402.1 1.426.48 1.667s1.14.703 1.14.703"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M50.717 75.649c-.12-.191-1.23-2.39-1.69-1.989-.46.402.1 1.426.48 1.667s1.14.703 1.14.703"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M49.967 83.662s-2.46-3.013-2.56-2.21c-.17 1.497 2.26 2.05 2.56 2.21"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M50.067 86.986c-.16-.151-1.8-1.989-2.15-1.487-.35.503.46 1.346.89 1.487.43.14 1.28.381 1.28.381"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M50.067 86.986c-.16-.151-1.8-1.989-2.15-1.487-.35.503.46 1.346.89 1.487.43.14 1.28.381 1.28.381"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M52.127 97.76c-.35-3.936-5.57-17.482 2.15-32.535"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M53.657 66.5s1.47-3.374 2.61-3.635.09 1.255-1.4 2.36"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M53.657 66.5s1.47-3.374 2.61-3.635.09 1.255-1.4 2.36"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M52.697 69.302s2.54-.633 2.18-1.346-1.95 1.105-2.18.773c-.23-.33-.03-1.837-1.09-2.219s-.01 2.41.54 2.32"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M52.697 69.302s2.54-.633 2.18-1.346-1.95 1.105-2.18.773c-.23-.33-.03-1.837-1.09-2.219s-.01 2.41.54 2.32"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M51.228 73.319c.09-.332 2.53-2.009 2.47-.864-.06 1.135-2.47.864-2.47.864M51.467 72.405s-1.61-3.545-1.9-2.792c-.55 1.396 1.66 2.561 1.9 2.792"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M50.918 75.669c.03-.111 2.05-1.085 2.52-.372s-.25.633-.35.713-2.36-.16-2.36-.16"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M50.918 75.669c.03-.111 2.05-1.085 2.52-.372s-.25.633-.35.713-2.36-.16-2.36-.16"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M50.357 80.338s2.29-1.265 1.76-1.858c-.53-.592-1.6 1.577-1.91 1.316s-.5-1.768-1.62-1.858.61 2.33 1.12 2.099"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M50.357 80.338s2.29-1.265 1.76-1.858c-.53-.592-1.6 1.577-1.91 1.316s-.5-1.768-1.62-1.858.61 2.33 1.12 2.099"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M50.387 84.254c0-.341 1.93-2.59 2.16-1.476.24 1.115-2.16 1.476-2.16 1.476"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M50.267 86.966c0-.111 1.7-1.577 2.33-1.015s-.07.673-.16.774-2.32.461-2.32.461"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M50.267 86.966c0-.111 1.7-1.577 2.33-1.015s-.07.673-.16.774-2.32.461-2.32.461"
    />
    <path
      fill="#e58a67"
      d="M17.962 75.93c-.14-.242-2.01-2.732-1.31-3.033.7-.302 1.55.903 1.62 1.476.07.572.16 1.687.16 1.687"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M17.962 75.93c-.14-.242-2.01-2.732-1.31-3.033.7-.302 1.55.903 1.62 1.476.07.572.16 1.687.16 1.687"
    />
    <path
      fill="#e58a67"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M27.413 79.565s-2.03-4.47-1.07-4.128c1.78.643 1.05 3.696 1.07 4.127"
    />
    <path
      fill="#e58a67"
      d="M31.112 81.553c-.08-.271-1.24-3.153-.49-3.264s1.27 1.276 1.18 1.848-.28 1.667-.28 1.667"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M31.112 81.553c-.08-.271-1.24-3.153-.49-3.264s1.27 1.276 1.18 1.848-.28 1.667-.28 1.667"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M48.702 96.716c-1.44-4.78-23.21-22.885-44.47-22.594"
    />
    <path
      fill="#e58a67"
      d="M6.013 74.122s-4.62-.221-5.55.914c-.93 1.134 1.37.803 3.44-.261"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M6.013 74.122s-4.62-.221-5.55.914c-.93 1.134 1.37.803 3.44-.261"
    />
    <path
      fill="#e58a67"
      d="M9.703 74.614s-2.12 2.51-2.72 1.707 2.33-1.586 2.08-2.028-2.06-1.055-1.9-2.48c.16-1.417 2.73 1.335 2.31 1.907"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M9.703 74.614s-2.12 2.51-2.72 1.707 2.33-1.586 2.08-2.028-2.06-1.055-1.9-2.48c.16-1.417 2.73 1.335 2.31 1.907"
    />
    <path
      fill="#e58a67"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M15.052 75.196c-.42-.09-3.67 1.727-2.35 2.31 1.32.572 2.35-2.31 2.35-2.31M13.882 74.955s-3.11-3.806-2.09-3.715c1.88.16 1.97 3.304 2.09 3.715"
    />
    <path
      fill="#e58a67"
      d="M17.872 76.17c-.14-.02-2.36 1.707-1.82 2.631s.85.08 1 0 1.13-2.761 1.13-2.761"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M17.872 76.17c-.14-.02-2.36 1.707-1.82 2.631s.85.08 1 0 1.13-2.761 1.13-2.761"
    />
    <path
      fill="#e58a67"
      d="M23.453 78.159s-2.7 1.877-3.07.944c-.37-.934 2.66-.934 2.54-1.426s-1.72-1.547-1.2-2.882 2.3 1.998 1.75 2.44"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M23.453 78.159s-2.7 1.877-3.07.944c-.37-.934 2.66-.934 2.54-1.426s-1.72-1.547-1.2-2.882 2.3 1.998 1.75 2.44"
    />
    <path
      fill="#e58a67"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M27.843 80.378c-.39-.201-3.99.723-2.87 1.617 1.12.893 2.87-1.617 2.87-1.617"
    />
    <path
      fill="#e58a67"
      d="M30.973 81.764c-.13-.06-2.72 1.044-2.44 2.068s.8.292.96.262 1.81-2.37 1.81-2.37"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M30.973 81.764c-.13-.06-2.72 1.044-2.44 2.068s.8.292.96.262 1.81-2.37 1.81-2.37"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M102.188 29.566h-39.43a.68.68 0 0 0-.68.683v4.69c0 .377.305.682.68.682h39.43c.376 0 .68-.305.68-.683V30.25a.68.68 0 0 0-.68-.683M101.548 36.957h-38.15c-.729 0-1.32.594-1.32 1.326v20.495c0 .732.591 1.326 1.32 1.326h38.15c.729 0 1.32-.594 1.32-1.326V38.283c0-.732-.591-1.326-1.32-1.326"
    />
    <path
      fill="rgba(255, 255, 255, .5)"
      d="M100.073 52.1c-.19-.03-.38-.03-.55 0l-13.97-24.24a1.53 1.53 0 0 0-2.09-.563c-.03.02-.06.05-.09.07-.25-.17-.55-.271-.87-.271s-.62.1-.87.271c-.03-.02-.06-.05-.09-.07a1.53 1.53 0 0 0-2.09.562L65.483 52.09a1.4 1.4 0 0 0-.58 0c-.83.15-1.38.954-1.23 1.788l.26 1.416a1.522 1.522 0 0 0 2.28 1.225l1.25-.442c.8-.281 1.22-1.165.93-1.958-.06-.181-.16-.342-.28-.472l12.85-22.283v21.238c-.17.06-.34.151-.49.282a1.546 1.546 0 0 0-.17 2.168l1.03 1.206c.29.341.72.542 1.16.542s.87-.201 1.16-.542l1.03-1.206c.55-.642.47-1.616-.17-2.169-.15-.13-.32-.22-.49-.28v-21.24l12.84 22.274c-.12.14-.22.31-.29.492-.28.803.14 1.677.93 1.958l1.33.472c.45.22.99.23 1.46-.04.48-.282.75-.784.76-1.306l.24-1.325c.15-.834-.4-1.637-1.23-1.788z"
    />
    <path
      fill="none"
      stroke="#e58a67"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M82.483 28.643v26.55M83.513 54.048l-1.03 1.205s-1-1.185-1.03-1.205M66.943 54.641l-1.49.522s-.28-1.526-.29-1.556M98.013 54.651l1.49.522s.28-1.526.29-1.556M80.753 28.643l-15.3 26.55M84.213 28.643l15.31 26.55"
    />
  </svg>
);
export default SvgSetGraphB01;
