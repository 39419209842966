import React, { FunctionComponent, useEffect, useState } from 'react'
import styled from 'styled-components'
import SvgLeftAngle from '../generated/svg/left-angle'
import SvgRightAngle from '../generated/svg/right-angle'
import { SwiperClass } from 'swiper/react'
import classNames from 'classnames'
import { useOn } from '../hooks/useSwiperClass'

const Outer = styled.div`
  display: flex;
  justify-content: space-between;
  pointer-events: none;
  gap: 1rem;
`

const ArrowContainer = styled.div`
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 5rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  pointer-events: all;
  transition: opacity 0.4s;
  &.disabled {
    opacity: 0;
    pointer-events: none;
  }
  &:nth-child(1) {
    padding-right: 0.1875rem;
  }
  &:nth-child(2) {
    padding-left: 0.1875rem;
  }
  svg {
    display: block;
    height: 1rem;
    width: auto;
  }
`

type SlideControlsProps = {
  swiper: SwiperClass | undefined
}
export const SlideControls: FunctionComponent<SlideControlsProps> = ({swiper}) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const [slideCount, setslideCount] = useState(0)
  useOn(swiper, 'activeIndexChange', swiper => setActiveIndex(swiper.realIndex))
  useOn(swiper, 'slidesLengthChange', swiper => setslideCount(swiper.slides.length))
  useEffect(() => {
    if(swiper){
      setslideCount(swiper.slides.length)
    }
  }, [swiper])
  return <Outer>
    <ArrowContainer className={classNames({disabled: activeIndex === 0})} onClick={() => swiper?.slidePrev()}>
      <SvgLeftAngle />
    </ArrowContainer>
    <ArrowContainer className={classNames({disabled: activeIndex === slideCount - 1})} onClick={() => swiper?.slideNext()}>
      <SvgRightAngle />
    </ArrowContainer>
  </Outer>
}