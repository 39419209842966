import { FaqGroup } from '../pages/faq-page'

type InformationArticle = {
  img: string
  title: string
  content: string
  slug: string
  publishedAt: string
}
type Settings = {
  themeFileRootPath: string,
  productId?: number
  variantId?: number
  variantProductId?: number
  productPrice?: number
  topics?: InformationArticle[]
  news: InformationArticle[]
  productTypeMap?: Record<string, string>
  faqs?: FaqGroup[],
  subsOrderInterval?: number | null
}
export const useSettings = (): Settings => {
  return Object.assign({}, ...Array.from(document.querySelectorAll('.settings')).map(el => {
    if(el.textContent){
      return JSON.parse(el.textContent)
    } else {
      return {}
    }
  }))
}