import React, { FunctionComponent, ReactNode, useState } from 'react'
import styled from 'styled-components'
import SvgMinus from '../generated/svg/minus'
import SvgPlus from '../generated/svg/plus'
import classNames from 'classnames'

const Outer = styled.div`
  display: flex;
  flex-direction: column;
`

const RowOuter = styled.div`
  transition: padding 0.4s;
  &.open {
    padding: 1rem 0;
  }
  & + & {
    margin-top: 1rem;
  }
`

const Label = styled.div`
  font-size: 0.875rem;
  line-height: 1.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
  border-bottom: 1px solid currentColor;
  margin-bottom: 0.5rem;
  transition: border-bottom-color 0.4s;
  cursor: pointer;
  ${RowOuter}.open & {
    border-bottom-color: transparent;
  }
`

const Icon = styled.div`
  svg {
    display: block;
    height: 0.875rem;
    width: auto;
  }
`

const Value = styled.div`
  display: grid;
  grid-template-rows: 0fr;
  overflow: hidden;
  transition: grid-template-rows 0.4s;
  padding: 0 0.5rem;
  ${RowOuter}.open & {
    grid-template-rows: 1fr;
  }
`

const ValueInner = styled.div`
  overflow: hidden;
  font-size: 0.875rem;
  line-height: 1.5;
`

type DetailTableItem = {
  label: ReactNode,
  value: ReactNode
}
type DetailTableProps = {
  items: DetailTableItem[]
}
export const DetailTable: FunctionComponent<DetailTableProps> = ({items}) => {
  return <Outer>
    {
      items.map((item, i) => {
        return <Row key={i} item={item} />
      })
    }
  </Outer>
}

type RowProps = {
  item: DetailTableItem
}
const Row: FunctionComponent<RowProps> = ({item}) => {
  const [open, setOpen] = useState(false)
  return <RowOuter className={classNames({open})}>
    <Label onClick={() => setOpen(v => !v)}>
      {item.label}
      <Icon>
        {
          open ? <SvgMinus /> : <SvgPlus />
        }
      </Icon>
    </Label>
    <Value>
      <ValueInner>
        {item.value}
      </ValueInner>
    </Value>
  </RowOuter>
}