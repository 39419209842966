import { css } from 'styled-components'

export const marked = css`
  ul {
    margin-left: 1em;
    li {
      position: relative;
      &::before {
        content: '・';
        display: block;
        position: absolute;
        top: 0;
        left: -1em;
      }
    }
  }
`