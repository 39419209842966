import * as React from "react";
import type { SVGProps } from "react";
const SvgShipFree = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 145 75"
    {...props}
  >
    <g
      fill="rgba(255, 255, 255, .5)"
      stroke="#3e3939"
      strokeLinejoin="round"
      strokeWidth={0.5}
    >
      <path d="M40.167 9.852H15.224c-.555 0-1.055.328-1.284.841L6.53 27.556.826 40.5a6.6 6.6 0 0 0-.556 2.668v17.419c0 .776.632 1.41 1.404 1.41h8.598c1.251-5.61 6.225-9.797 12.177-9.797s10.926 4.199 12.178 9.797h5.54a1.41 1.41 0 0 0 1.403-1.41V11.262a1.41 1.41 0 0 0-1.404-1.41ZM34.073 33.93a.76.76 0 0 1-.762.766H12.667a.772.772 0 0 1-.697-1.083L19.446 16.6a.75.75 0 0 1 .697-.46H33.3l.01.012c.425 0 .763.339.763.765zM86.539 21.158c0-1.597-.903-2.591-2.503-2.591h-4.821v5.489h4.266c1.85 0 3.058-.963 3.058-2.898Z" />
      <path d="M45.337 49.916h95.441V1.575c0-.719-.58-1.302-1.295-1.302H45.99c-.715 0-1.295.583-1.295 1.302v48.91c0 .013.008.025.008.038a.64.64 0 0 1 .634-.607Zm65.796-34.236h13.95v2.897h-10.555v4.593h9.751v2.668h-9.751v5.478h10.959v2.898h-14.354zm-17.303 0h13.952v2.897H97.225v4.593h9.751v2.668h-9.75v5.478h10.958v2.898H93.83zm-18.021 0h8.695c3.003 0 5.68 1.75 5.68 5.03 0 1.17-.38 2.285-1.131 3.17-.838.963-1.698 1.214-2.884 1.498.838.132 1.567.252 2.274.788 1.067.81 1.263 2.209 1.263 3.477v4.57H86.31v-4.209c0-1.575-.555-3.05-2.372-3.05h-4.723v7.26h-3.406zm-15.814 0h13.081v2.897h-9.674v4.8h8.77v2.898h-8.77v7.95h-3.407V15.68h-.01zM33.31 16.15l-.01-.01H20.143a.75.75 0 0 0-.697.459L11.97 33.613a.772.772 0 0 0 .696 1.083h20.645a.76.76 0 0 0 .762-.766V16.916a.76.76 0 0 0-.762-.765Zm-.882 16.895H14.004l6.704-15.254h11.72z" />
      <path d="M14.004 33.045h18.424V17.79h-11.72zM144.075 49.916h.011M144.075 49.916H45.337a.64.64 0 0 0-.634.607c0 .013-.008.025-.008.038v10.782c0 .36.294.645.642.645h59.485c1.251-5.61 6.225-9.798 12.177-9.798s10.927 4.2 12.178 9.798h14.898c.36 0 .642-.296.642-.645V50.56a.644.644 0 0 0-.642-.645ZM22.452 54.76c-5.488 0-9.936 4.47-9.936 9.983s4.448 9.983 9.936 9.983 9.935-4.47 9.935-9.983-4.448-9.983-9.935-9.983Zm0 12.334c-1.293 0-2.34-1.053-2.34-2.352s1.047-2.35 2.34-2.35 2.339 1.052 2.339 2.35-1.048 2.351-2.34 2.351Z" />
      <path d="M22.451 62.392c-1.292 0-2.34 1.052-2.34 2.35s1.048 2.351 2.34 2.351 2.34-1.052 2.34-2.35-1.048-2.351-2.34-2.351ZM116.998 54.76c-5.487 0-9.936 4.47-9.936 9.983s4.449 9.983 9.936 9.983 9.936-4.47 9.936-9.983-4.448-9.983-9.936-9.983Zm0 12.334c-1.292 0-2.34-1.053-2.34-2.352s1.048-2.35 2.34-2.35 2.34 1.052 2.34 2.35-1.048 2.351-2.34 2.351Z" />
      <path d="M116.998 62.392c-1.292 0-2.34 1.052-2.34 2.35s1.048 2.351 2.34 2.351 2.34-1.052 2.34-2.35-1.048-2.351-2.34-2.351ZM59.984 15.68h.011M63.402 34.225v-7.95h8.77v-2.897h-8.77v-4.8h9.674V15.68h-13.08v18.545zM79.215 26.953h4.723c1.817 0 2.372 1.476 2.372 3.05v4.21h3.396v-4.57c0-1.268-.196-2.668-1.263-3.477-.707-.536-1.436-.656-2.274-.788 1.186-.284 2.046-.535 2.884-1.498.75-.885 1.131-2 1.131-3.17 0-3.28-2.677-5.03-5.68-5.03h-8.696v18.533h3.407zm0-8.387h4.82c1.6 0 2.504.995 2.504 2.592 0 1.935-1.208 2.897-3.058 2.897h-4.266zM108.184 31.316H97.225v-5.478h9.751V23.17h-9.75v-4.593h10.556V15.68H93.83v18.534h14.354zM125.487 31.316h-10.959v-5.478h9.751V23.17h-9.751v-4.593h10.556V15.68h-13.951v18.534h14.354z" />
    </g>
    <path
      fill="#3e3939"
      d="M32.157 18.064v14.707H14.418l6.464-14.707h11.264m.555-.546H20.534l-6.954 15.8h19.11v-15.8z"
    />
    <path
      fill="none"
      stroke="#3e3939"
      strokeMiterlimit={10}
      strokeWidth={0.5}
      d="M44.62 47.193h95.842"
    />
  </svg>
);
export default SvgShipFree;
