type Variant = {
  id: number
  state: string
  sku: string
  is_master: boolean
  for_sale: boolean
  product_id: number
  description: string
  description_mobile: string
  position: number
  cost: number
  volume: number
  list_price: number
  sales_price: number
  limit_quantity: boolean
  min_quantity: number
  max_quantity: number
  customer_limit_quantity: string
  is_new: boolean
  is_sale: boolean
  products_addable_on_mypage: boolean
  visible_in_cart: boolean
  visible_in_email: boolean
  visible_in_report: boolean
  point_exchangeable: boolean
  link_number: any
  deleted_at: any
  created_at: string
  updated_at: string
}

export type ProductData = {
  id: number
  master: Variant
  variant: Variant
  description: string
  limit_quantity: number
  min_quantity: number
  max_quantity: number
  thumbnail: string
  thumbnails: Array<string>
  is_out_of_stock: boolean
  list_price: string
  sales_price: string
  list_price_include_tax: string
  sales_price_include_tax: string
  option_types: Array<any>
  option_values: Array<any>
  split_option_values: {}
  distribution_club: boolean
  set: boolean
  name: string
  maker: any
  average_star_num: any
  product_description: string
  variant_description: string
  category: string
  caution: string
  extra_text: Array<any>
  option01: string
  option02: string
  option03: string
  option04: string
  option05: string
  option06: string
  option07: string
  option08: string
  option09: string
  option10: string
  list_price_text: string
  sales_price_text: string
  tax_included_text: string
}

export const normalizePrice = (price: string) => {
  return Number(price.replace(/円|,/g, ''))
}


export const getProductData = async (sku: string) => {
  const res = await fetch(`/shop/products/${encodeURIComponent(sku)}`, {
    headers: {
      'Accept': 'application/json'
    }
  })

  return res.json() as Promise<ProductData>
}