import * as React from "react";
import type { SVGProps } from "react";
const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 98 51"
    {...props}
  >
    <path
      fill="currentcolor"
      d="m0 19.98.99-1.283c2.265 2.337 4.34 3.41 7.366 3.41s5.197-1.935 5.197-4.56v-.057c0-2.433-1.143-3.85-5.806-4.904C2.817 11.476.82 9.598.82 6.111v-.057C.819 2.624 3.617 0 7.424 0c2.988 0 4.967.92 7.042 2.797l-.97 1.34c-1.942-1.876-3.884-2.585-6.13-2.585-3.026 0-5.025 1.935-5.025 4.33v.057c0 2.433 1.123 3.908 5.996 5.019 4.759 1.092 6.757 2.988 6.757 6.34v.058c0 3.717-2.855 6.284-6.814 6.284-3.293 0-5.805-1.207-8.28-3.678zM17.055.326h1.523V15.44L31.693.326h2.093l-8.984 10.153 9.365 12.854h-1.999l-8.451-11.628-5.101 5.785v5.843h-1.523V.326zM35.69.326h1.446l14.181 20.019V.325h1.466v23.008H51.64L37.155 2.893v20.44H35.69zM13.21 50l-4.397-7.356h-3.56V50H.724V27.012h9.403q3.684 0 5.71 2.011c1.352 1.341 2.037 3.2 2.037 5.575v.076c0 1.859-.4 3.372-1.218 4.54-.818 1.17-1.865 2.031-3.179 2.587l5.026 8.218H13.21zm.095-15.134c0-1.092-.323-1.916-.933-2.471-.628-.556-1.503-.824-2.607-.824H5.273v6.61H9.86c1.123 0 1.98-.288 2.57-.882s.894-1.38.894-2.357v-.057zM20.272 50V27.012h15.475v4.501H24.726v4.656h9.688v4.502h-9.688v4.827H35.88V50H20.253zM53.925 50V35l-5.787 9.828h-.114l-5.73-9.713v14.904h-4.472V27.031h4.872l5.406 9.732 5.406-9.732h4.873v22.988h-4.473zM60.492 50V27.012h15.475v4.501H64.946v4.656h9.688v4.502h-9.688v4.827H76.1V50H60.473zM96.22 43.046c-.533 1.398-1.276 2.625-2.246 3.64-.971 1.034-2.132 1.839-3.465 2.433S87.692 50 86.093 50H78.08V27.012h8.013c1.6 0 3.084.287 4.416.862a10.3 10.3 0 0 1 3.464 2.413c.971 1.035 1.714 2.242 2.247 3.621s.799 2.893.799 4.521v.077c0 1.628-.266 3.122-.8 4.54m-3.94-4.54c0-1.016-.153-1.935-.457-2.797s-.724-1.59-1.257-2.203a5.5 5.5 0 0 0-1.96-1.437c-.762-.345-1.6-.517-2.532-.517h-3.502v13.87h3.502c.914 0 1.77-.173 2.532-.499a5.55 5.55 0 0 0 1.96-1.417 6.9 6.9 0 0 0 1.257-2.165c.304-.824.456-1.762.456-2.797v-.057z"
    />
  </svg>
);
export default SvgLogo;
