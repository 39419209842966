import React from 'react';
import { FunctionComponent } from 'react';
import { createGlobalStyle, css } from 'styled-components';

const Css = createGlobalStyle`
  :root, :host {
    all: unset;
    --page-margin: 5rem;
    --page-inside-margin: 5rem;
    --page-max-width: 84rem;
    --side-margin: calc((100vw - min(100%, var(--page-max-width))) / 2);
    @media (max-width: 75rem){
      --page-margin: 3rem
    }
    @media (max-width: 50rem){
      --page-margin: 2rem
    }

    color: #3F3939;
    font-family: 'Zen Kaku Gothic New', sans-serif;
  }
  a {
    color: inherit;
    text-decoration: inherit;
  }
  :is(sup) {
    font-size: 0.625rem !important;
    vertical-align: super !important;
    white-space: nowrap !important;
  }
  .swiper-wrapper {
    /* transition-timing-function: cubic-bezier(0.25, 0, 0, 1)!important;
    transition-duration: 0.5s; */
  }
`

export const shadowOuterStyle = css`
  all: unset;
  line-height: 1;
  font-family: 'Zen Kaku Gothic New', sans-serif;
  font-weight: 400;
`

export const RootCss: FunctionComponent = () => {
  return <>
    <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/swiper@11/swiper-bundle.min.css" />
    <Css />
  </>
}