import * as React from "react";
import type { SVGProps } from "react";
const SvgThinRightArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 79.403 7.953"
    {...props}
  >
    <path
      fill="none"
      stroke="currentcolor"
      strokeWidth={0.5}
      d="M0 7.703h79.294L63.904.225"
    />
  </svg>
);
export default SvgThinRightArrow;
