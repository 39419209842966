import * as React from "react";
import type { SVGProps } from "react";
const SvgMoney = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 147 75"
    {...props}
  >
    <g stroke="#3e3939" strokeLinejoin="round" strokeWidth={0.5}>
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M35.064 55.568v2.93c0 .57-.653 1.106-1.785 1.585 1.615.679 4.22 1.24 7.444 1.595v-5.11c-2.221-.245-4.143-.588-5.66-1Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M35.064 55.568c1.516.412 3.438.755 5.659 1v-1.085c-2.22-.245-4.143-.588-5.66-1z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M35.064 49.373v2.93c0 .185-.089.365-.218.543.13.177.218.357.218.542 0 .033-.022.1-.022.1h.022v.995c1.516.411 3.438.754 5.659 1v-5.11c-2.221-.246-4.143-.589-5.66-1Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M35.064 49.373c1.516.411 3.438.755 5.659 1v-1.075c-2.22-.245-4.143-.588-5.66-.999z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M35.064 43.188v2.93c0 .183-.087.362-.214.538.127.176.214.354.214.537 0 .033-.022.1-.022.1h.022v1.006c1.516.411 3.438.754 5.659.999v-5.11c-2.221-.245-4.143-.588-5.66-1Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M35.064 43.188c1.516.412 3.438.755 5.659 1v-1.075c-2.22-.245-4.143-.587-5.66-.999z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M35.064 37.004v2.929c0 .184-.087.362-.214.538.127.176.214.354.214.537 0 .033-.022.1-.022.1h.022v1.006c1.516.412 3.438.754 5.659 1v-5.11c-2.221-.245-4.143-.589-5.66-1Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M35.064 37.004c1.516.411 3.438.755 5.659 1v-1.086c-2.22-.245-4.143-.588-5.66-.999z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M35.064 30.808v2.93c0 .185-.088.366-.218.543.13.178.218.358.218.543 0 .033-.022.1-.022.1h.022v.995c1.516.411 3.438.754 5.659.999v-4.433c0-.247.09-.463.217-.655-2.31-.247-4.312-.597-5.876-1.022Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M35.064 30.808c1.564.425 3.565.775 5.876 1.022.217-.332.573-.564.998-.564h2.585v-.23c-3.824-.204-7.13-.67-9.46-1.301zM66.271 22.998v-.454h-.013a1 1 0 0 1-.202.454z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M35.064 24.624v2.93c0 .183-.087.361-.214.537.127.176.214.354.214.537 0 .034-.022.1-.022.1h.022v1.006c2.329.632 5.635 1.098 9.46 1.302v-2.64c0-.673.544-1.22 1.215-1.22h2.654v-1.135c-5.44-.032-10.246-.58-13.33-1.417Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M35.064 24.624c3.083.837 7.888 1.385 13.329 1.417v-1.086c-5.442-.031-10.244-.58-13.33-1.416zM64.357 22.998h1.699a1 1 0 0 0 .202-.454c.005-.034.025-.066.025-.1 0-.188-.09-.37-.224-.55-.282.392-.862.764-1.702 1.104Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M35.064 22.444c0 .033-.022.1-.022.1h.022v.995c3.085.836 7.887 1.385 13.329 1.416v-.738c0-.673.544-1.219 1.215-1.219h14.75c.84-.34 1.419-.712 1.7-1.104.127-.175.213-.353.213-.536v-5.01h-.013c-.257 1.943-7.926 3.503-17.37 3.503-9.606 0-17.394-1.613-17.394-3.602 0-.181.085-.357.21-.53l-.007-.01c-.127.177-.215.356-.215.54 0 .033.023.077.023.11v3.908c2.223.605 3.559 1.358 3.559 2.177Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M48.877 18.775c-8.715 0-15.9-1.33-17.174-3.057-.124.174-.209.35-.209.53 0 1.99 7.788 3.603 17.395 3.603 9.443 0 17.112-1.56 17.37-3.503.004-.033.024-.066.024-.1 0-.185-.09-.366-.22-.544-1.238 1.74-8.443 3.071-17.186 3.071Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M48.877 18.775c8.743 0 15.948-1.33 17.186-3.071.123-.174.208-.35.208-.53v-5.01h-.013c-.257 1.942-7.926 3.502-17.37 3.502-9.606 0-17.394-1.613-17.394-3.602 0-.18.085-.357.21-.53l-.007-.009c-.127.176-.215.355-.215.539 0 .033.023.077.023.11v4.888s-.023.078-.023.111c0 .183.088.36.215.536l.006.01c1.273 1.725 8.46 3.056 17.174 3.056Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M48.877 12.59c-8.715 0-15.9-1.33-17.174-3.056-.124.173-.209.35-.209.53 0 1.99 7.788 3.602 17.395 3.602 9.443 0 17.112-1.56 17.37-3.502.004-.034.024-.066.024-.1 0-.186-.09-.367-.22-.545-1.238 1.741-8.443 3.072-17.186 3.072Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M48.877 12.59c8.743 0 15.948-1.33 17.186-3.07.123-.174.208-.35.208-.531v-5.01h-.013c-.257 1.943-7.926 3.502-17.37 3.502-9.606 0-17.394-1.612-17.394-3.602S39.28.278 48.883.277h-.006c-9.615 0-17.395 1.608-17.395 3.602 0 .034.023.078.023.111v4.888s-.023.078-.023.11c0 .183.088.362.215.537l.006.009c1.273 1.726 8.46 3.057 17.174 3.057Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M31.494 3.88c0 1.989 7.788 3.601 17.394 3.601 9.444 0 17.113-1.559 17.37-3.502.005-.033.025-.066.025-.1 0-1.989-7.788-3.602-17.395-3.602h-.005c-9.604 0-17.389 1.613-17.389 3.602Zm12.454-2.007-1.968.166c.608-.066 1.271-.121 1.968-.166Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M39.363 2.45c-1.349.276-2.144.62-2.144.986 0 .953 5.227 1.718 11.67 1.718s11.67-.765 11.67-1.718-5.228-1.718-11.67-1.718c-1.26 0-2.465.022-3.592.077M41.98 2.04l1.967-.167c-.696.044-1.359.1-1.967.166ZM.494 52.844c-.13.178-.219.359-.219.544 0-.185.089-.366.22-.543zM35.064 58.498v-5.01h-.022s.022-.067.022-.1c0 1.99-7.788 3.602-17.394 3.602S.275 55.377.275 53.388c0 .033.023.078.023.11v4.889s-.023.077-.023.11c0 1.984 7.78 3.602 17.395 3.602 6.866 0 12.78-.821 15.609-2.017 1.132-.478 1.785-1.014 1.785-1.585Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M17.67 56.99c9.606 0 17.394-1.613 17.394-3.602 0-.185-.089-.365-.218-.542-1.266 1.735-8.456 3.058-17.176 3.058S1.761 54.572.493 52.844c-.13.178-.219.359-.219.544 0 1.99 7.788 3.602 17.394 3.602Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M17.67 55.904c8.72 0 15.91-1.324 17.176-3.058.13-.178.218-.358.218-.544v-5.01h-.022s.022-.066.022-.1c0 1.99-7.788 3.603-17.394 3.603S.275 49.182.275 47.193c0 .033.023.077.023.11v4.888s-.023.078-.023.111c0 .185.089.364.219.542 1.268 1.728 8.457 3.06 17.176 3.06Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M17.67 50.795c9.606 0 17.394-1.613 17.394-3.602 0-.183-.087-.361-.214-.537-1.255 1.737-8.45 3.064-17.18 3.064S1.746 48.385.49 46.655c-.127.176-.215.354-.215.538 0 1.99 7.788 3.602 17.394 3.602Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M17.67 49.72c8.73 0 15.925-1.327 17.18-3.064.127-.176.214-.355.214-.538v-5.01h-.022s.022-.066.022-.1c0 1.99-7.788 3.603-17.394 3.603S.275 42.998.275 41.008c0 .033.023.078.023.111v4.888s-.023.077-.023.11c0 .183.088.362.215.538 1.257 1.73 8.452 3.065 17.18 3.065Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M17.67 44.61c9.606 0 17.394-1.612 17.394-3.602 0-.183-.087-.361-.214-.537-1.255 1.738-8.45 3.064-17.18 3.064S1.746 42.2.49 40.47c-.127.176-.215.355-.215.538 0 1.99 7.788 3.602 17.394 3.602ZM.494 34.28c-.13.177-.219.358-.219.544 0-.186.089-.366.22-.544z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M17.67 43.535c8.73 0 15.926-1.326 17.18-3.064.127-.176.214-.354.214-.538v-5.01h-.022s.022-.066.022-.1c0 1.99-7.788 3.603-17.394 3.603S.275 36.813.275 34.824c0 .033.023.077.023.11v4.888s-.023.078-.023.111c0 .183.088.362.215.537 1.257 1.73 8.452 3.065 17.18 3.065Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M17.67 38.426c9.606 0 17.394-1.613 17.394-3.602 0-.185-.089-.365-.218-.543-1.265 1.735-8.455 3.059-17.176 3.059S1.761 36.008.493 34.28c-.13.178-.219.358-.219.544 0 1.99 7.788 3.602 17.394 3.602Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M17.67 37.34c8.72 0 15.91-1.324 17.176-3.059.13-.177.218-.358.218-.543v-5.01h-.022s.022-.066.022-.1c0 1.99-7.788 3.602-17.394 3.602S.275 30.618.275 28.628c0 .034.023.078.023.111v4.888s-.023.077-.023.11c0 .185.089.365.219.542v.001c1.268 1.728 8.457 3.06 17.176 3.06Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M17.67 32.23c9.606 0 17.394-1.612 17.394-3.602 0-.183-.087-.361-.214-.537-1.255 1.738-8.45 3.064-17.18 3.064S1.746 29.82.49 28.09c-.127.176-.215.355-.215.538 0 1.99 7.788 3.602 17.395 3.602ZM17.664 18.842h-.005c-9.615 0-17.384 1.607-17.384 3.602 0-1.99 7.785-3.601 17.389-3.602Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M17.67 31.155c8.73 0 15.926-1.326 17.18-3.064.127-.176.214-.354.214-.538v-5.01h-.022s.022-.066.022-.1c0 1.99-7.788 3.603-17.394 3.603S.275 24.433.275 22.444c0 .033.023.077.023.11v4.888s-.023.078-.023.111c0 .183.088.361.215.537 1.257 1.73 8.452 3.065 17.18 3.065Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M17.67 26.046c9.606 0 17.394-1.613 17.394-3.602 0-.82-1.336-1.572-3.56-2.177-3.177-.865-8.183-1.425-13.835-1.425h-.005C8.06 18.842.275 20.455.275 22.444s7.788 3.602 17.394 3.602Zm-4.94-5.597-1.966.166c.607-.066 1.27-.121 1.967-.166Z"
      />
      <path
        fill="none"
        d="M8.144 21.014C6.796 21.29 6 21.634 6 22c0 .953 5.227 1.718 11.67 1.718S29.34 22.953 29.34 22s-5.228-1.718-11.67-1.718a74 74 0 0 0-3.592.078"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="m10.764 20.615 1.967-.166c-.697.044-1.36.1-1.967.166ZM44.523 69.415v-38.15h-2.585c-.425 0-.781.233-.998.565a1.2 1.2 0 0 0-.217.655v41.019c0 .673.544 1.219 1.215 1.219h95.901c.671 0 1.215-.546 1.215-1.22v-2.869H45.739a1.217 1.217 0 0 1-1.216-1.22Z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M49.608 66.455a1.217 1.217 0 0 1-1.215-1.219v-38.06h-2.654c-.671 0-1.216.547-1.216 1.22v41.019c0 .673.544 1.219 1.216 1.219h95.9c.672 0 1.216-.546 1.216-1.22v-2.959z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M145.508 22.998h-95.9c-.671 0-1.215.546-1.215 1.22v41.018c0 .673.544 1.22 1.215 1.22h95.9c.672 0 1.216-.547 1.216-1.22V24.217c0-.673-.544-1.219-1.216-1.219Zm-35.64 27.985H99.867v8.878h-4.63v-8.878H85.234v-2.97h10.001v-.831l-2.022-2.794h-7.979v-2.97h6.321l-7.57-11.814h5.25l8.31 13.554 8.31-13.555h5.249l-7.57 11.815h6.322v2.97h-7.979l-2.023 2.794h.012v.83h10z"
      />
      <path
        fill="rgba(255, 255, 255, .5)"
        d="M99.867 47.182h-.012l2.023-2.794h7.98v-2.97h-6.323l7.57-11.814h-5.25l-8.31 13.554-8.31-13.554h-5.249l7.57 11.814h-6.32v2.97h7.978l2.022 2.794v.83h-10v2.971h10v8.878h4.63v-8.878h10.002v-2.97H99.867z"
      />
    </g>
    <path
      fill="none"
      stroke="#3e3939"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={0.5}
      d="M35.836 57.123v2.283M38.445 57.844v2.283M35.836 50.928v2.294M38.445 51.66v2.282M35.836 44.743v2.283M38.445 45.475v2.283M35.836 38.559v2.283M38.445 39.28v2.282M35.836 32.363v2.283M38.445 33.095v2.283M35.836 26.179v2.283M38.445 26.91v2.283M41.053 27.32v2.283M43.66 27.432v2.283M33.217 19.263v1.57M35.836 19.995v2.283M38.445 20.715v2.283M41.053 21.136v2.283M43.66 21.247v2.283M46.28 21.247v2.283M48.887 21.27v1.917M33.217 13.078v2.283M35.836 13.799v2.283M38.445 14.53v2.283M41.053 14.94v2.284M43.66 15.063v2.283M46.28 15.063v2.283M48.887 15.085v2.283M33.217 6.883v2.283M35.836 7.614v2.283M38.445 8.335v2.294M41.053 8.756v2.283M43.66 8.867v2.283M46.28 8.867v2.283M48.887 8.89v2.283"
    />
    <path
      fill="none"
      stroke="#3e3939"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M45.297 1.795a74 74 0 0 1 3.592-.077c6.442 0 11.67.765 11.67 1.718s-5.228 1.718-11.67 1.718-11.67-.765-11.67-1.718c0-.366.795-.71 2.144-.987M41.98 2.04c.608-.067 1.271-.123 1.968-.167"
    />
    <path
      fill="none"
      stroke="#3e3939"
      strokeLinecap="round"
      strokeMiterlimit={10}
      strokeWidth={0.5}
      d="M2 56.392v2.283M4.607 57.123v2.283M7.227 57.844v2.283M9.834 58.265v2.283M12.443 58.376v2.283M15.05 58.376v2.283M17.67 58.398v2.284M2 50.207v2.283M4.607 50.928v2.294M7.227 51.66v2.282M9.834 52.08v2.283M12.443 52.191v2.283M15.05 52.191v2.283M17.67 52.214v2.283M2 44.023v2.283M4.607 44.743v2.283M7.227 45.475v2.283M9.834 45.885v2.283M12.443 45.996v2.283M15.05 45.996v2.283M17.67 46.03v2.282M2 37.827v2.283M4.607 38.559v2.283M7.227 39.28v2.282M9.834 39.7v2.283M12.443 39.812v2.283M15.05 39.812v2.283M17.67 39.834v2.283M2 31.643v2.283M4.607 32.363v2.283M7.227 33.095v2.283M9.834 33.505v2.283M12.443 33.627v2.283M15.05 33.627v2.283M17.67 33.65v2.283M2 25.447v2.283M4.607 26.179v2.283M7.227 26.91v2.283M9.834 27.32v2.283M12.443 27.432v2.283M15.05 27.432v2.283M17.67 27.465v2.283"
    />
    <path
      fill="none"
      stroke="#3e3939"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={0.5}
      d="M14.078 20.36a74 74 0 0 1 3.592-.078c6.443 0 11.67.765 11.67 1.718s-5.227 1.718-11.67 1.718S6 22.953 6 22c0-.366.796-.71 2.144-.986M10.764 20.616c.608-.067 1.27-.122 1.967-.167"
    />
  </svg>
);
export default SvgMoney;
