import React, { FunctionComponent } from 'react'
import styled from 'styled-components'
import { SectionHead } from '../components/section-header'
import SvgCycleGraph01 from '../generated/svg/cycle-graph-01'
import { pageMaxWidth } from '../styles/page-max-width'
import SvgCycleGraph04 from '../generated/svg/cycle-graph-04'
import SvgCycleGraph03 from '../generated/svg/cycle-graph-03'
import SvgCycleGraph02 from '../generated/svg/cycle-graph-02'

const Outer = styled.div`
  ${pageMaxWidth}
`

const Inner = styled.div`
  padding: 0 var(--page-inside-margin);
  margin-top: 4rem;
  @media (max-width: 90rem){
    padding-right: 0;
    padding-left: 0;
  }
  @media (max-width: 40rem){
    margin-top: 2rem;
    padding: 0;
  }
`

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr auto;
  gap: 0 3rem;
  @media (max-width: 60rem){
    grid-template-columns: auto 1fr;
  }
  @media (max-width: 40rem){
    display: flex;
    flex-direction: column;
  }
`

const Item = styled.div`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span 3;
  padding: 2rem 0;
  @media (max-width: 60rem){
    grid-column: span 2;
  }
  @media (max-width: 40rem){
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  & + & {
    border-top: 1px solid currentColor;
  }
`

const Text1 = styled.div`
  font-size: 1.25rem;
  line-height: normal;
  letter-spacing: 0.06em;
  font-family: var(--en-font);
  border-radius: 100rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 6rem;
  height: 6rem;
  align-self: center;
  @media (max-width: 40rem){
    font-size: 0.75rem;
    line-height: normal;
    letter-spacing: 0.06em;
    align-self: center;
    width: 4.5rem;
    height: 4.5rem;
  }
`

const TextContainer = styled.div``

const Text2 = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  font-weight: 700;
  @media (max-width: 40rem){
    text-align: center;
    margin-top: 1.5rem;
  }
`

const Text3 = styled.div`
  font-size: 0.8125rem;
  line-height: 2;
  @media (max-width: 40rem){
    margin-top: 0.5rem;
  }
`

const GraphContainer = styled.div`
  margin: -1rem 0;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 60rem){
    grid-column: span 2;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 40rem){
    margin-top: 1rem;
    overflow-x: scroll;
    margin-left: calc(var(--page-margin) * -1);
    margin-right: calc(var(--page-margin) * -1);
    display: block;
    max-width: calc(100% + var(--page-margin) * 2);
  }
  svg {
    display: block;
    height: 10.5rem;
    width: auto;
    @media (max-width: 40rem){
      height: 8rem;
      width: auto;
      padding-left: var(--page-margin);
      padding-right: var(--page-margin);
    }
  }
`

const Text4 = styled.div`
  font-size: 0.5625rem;
  line-height: normal;
`

type CycleSectionProps = {

}
export const CycleSection1: FunctionComponent<CycleSectionProps> = () => <Outer>
  <SectionHead>Cycle</SectionHead>
  <Inner>
    <Container>
      <Item>
        <Text1 style={{backgroundColor: '#B7CEB4'}}>
          DAY 1
        </Text1>
        <TextContainer>
          <Text2>
            角質ケア
          </Text2>
          <Text3>
            1日目、洗顔後の乾いた肌にピールケアセラム1.0をお使いください。一円玉大を目の周り・口元・生え際を避けて塗布し、やさしく馴染ませます。
          </Text3>
        </TextContainer>
        <GraphContainer>
          <SvgCycleGraph01 />
        </GraphContainer>
      </Item>
      <Item>
        <Text1 style={{backgroundColor: '#EFBAB4'}}>
          DAY 2
        </Text1>
        <TextContainer>
          <Text2>
            集中ケア
          </Text2>
          <Text3>
            2日目、レチノダーマクリーム0.1を小豆1粒大で塗布してください。肌が慣れてきたら少しづつ使用量をパール大１粒へ徐々に増やしてください。
          </Text3>
        </TextContainer>
        <GraphContainer>
          <SvgCycleGraph02 />
        </GraphContainer>
      </Item>
      <Item>
        <Text1 style={{backgroundColor: '#D9AFCB'}}>
          DAY<br />
          3・4
        </Text1>
        <TextContainer>
          <Text2>
            保湿ケア（お休み日）
          </Text2>
          <Text3>
            3～4日目は肌を労わるケアとして大きめパール1粒大のナイアシナグロージェルを塗布し、やさしく馴染ませてください。
          </Text3>
        </TextContainer>
        <GraphContainer>
          <SvgCycleGraph03 />
        </GraphContainer>
      </Item>
    </Container>
    <Text4>
      ※ 妊娠中、授乳中は肌が敏感になりやすいためご使用をお控えいただくことをおすすめしています。<br />
      ※ ピールケアセラムにはナッツ由来の成分を配合しております。ナッツアレルギーがある方ご使用をお控えください。<br />
      ※ お肌が敏感な方、アレルギーをお持ちの方は、使用前に製品のパッチテストを実施していただくことをおすすめします。
    </Text4>
  </Inner>
</Outer>
export const CycleSection2: FunctionComponent<CycleSectionProps> = () => <Outer>
  <SectionHead>Cycle</SectionHead>
  <Inner>
    <Container>
      <Item>
        <Text1 style={{backgroundColor: '#B7CEB4'}}>
          DAY 1
        </Text1>
        <TextContainer>
          <Text2>
            角質ケア
          </Text2>
          <Text3>
            1日目、洗顔後の乾いた肌にピールケアセラム1.0をお使いください。一円玉大を目の周り・口元・生え際を避けて塗布し、やさしく馴染ませます。
          </Text3>
        </TextContainer>
        <GraphContainer>
          <SvgCycleGraph01 />
        </GraphContainer>
      </Item>
      <Item>
        <Text1 style={{backgroundColor: '#C6B7D8'}}>
          DAY 2
        </Text1>
        <TextContainer> 
          <Text2>
            集中ケア
          </Text2>
          <Text3>
            2日目、ハイドロライトクリーム2.5を目立たないところに塗布してください。反応がなかったらパール1粒へ、徐々に量を増やしてください。
          </Text3>
        </TextContainer>
        <GraphContainer>
          <SvgCycleGraph04 />
        </GraphContainer>
      </Item>
      <Item>
        <Text1 style={{backgroundColor: '#D9AFCB'}}>
          DAY<br />
          3・4
        </Text1>
        <TextContainer>
          <Text2>
            保湿ケア（お休み日）
          </Text2>
          <Text3>
            3～4日目は肌を労わるケアとして大きめパール1粒大のナイアシナグロージェルを塗布し、やさしく馴染ませてください。
          </Text3>
        </TextContainer>
        <GraphContainer>
          <SvgCycleGraph03 />
        </GraphContainer>
      </Item>
    </Container>
    <Text4>
      ※ 妊娠中、授乳中は肌が敏感になりやすいためご使用をお控えいただくことをおすすめしています。<br />
      ※ ピールケアセラムにはナッツ由来の成分を配合しております。ナッツアレルギーがある方ご使用をお控えください。<br />
      ※ お肌が敏感な方、アレルギーをお持ちの方は、使用前に製品のパッチテストを実施していただくことをおすすめします。
    </Text4>
  </Inner>
</Outer>