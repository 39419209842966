import * as React from "react";
import type { SVGProps } from "react";
const SvgLeftAngle = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 13.069 23.722"
    {...props}
  >
    <path
      fill="none"
      stroke="currentcolor"
      d="m12.723 23.361-12-11.5 12-11.5"
    />
  </svg>
);
export default SvgLeftAngle;
