import * as React from "react";
import type { SVGProps } from "react";
const SvgSetGraphD02 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    data-name="top-graph-02"
    viewBox="0 0 124 80"
    {...props}
  >
    <defs>
      <clipPath id="cbcbceadb959e35f8b6d11f3db2022200f9a37c9-a">
        <path fill="none" d="M0 0h124v80H0z" />
      </clipPath>
    </defs>
    <g fill="none" clipPath="url(#cbcbceadb959e35f8b6d11f3db2022200f9a37c9-a)">
      <path
        fill="#fff"
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M117.543 60.203c-.494-1.698-2.374-2.52-2.374-2.52-1.559-1.607-2.82-1.297-2.82-1.297-1.395-1.04-2.938-.584-2.938-.584-1.52-.749-3.557-.21-3.557-.21-1.872-.53-3.47.283-3.47.283-1.959-.886-3.228.922-3.228.922-1.668-.749-3.369.922-3.369.922-2.773-.009-3.47 1.927-3.47 1.927-2.32.694-2.39 2.402-2.39 2.402-2.162.858-2.044 2.237-1.927 2.72-1.003 1.873-2.013 5.178-1.316 10.484 0 0 .141.128.353.283.548.383 1.175-.283.94-.977-.314-.95-.815-3.625.211-7.853.165.21.69 1.068.69 1.068 1.653 2.246 2.953 2.813 2.953 2.813 1.489 1.68 3.878 1.059 3.878 1.059 2.17.95 2.907-.137 2.907-.137 2.413 1.589 3.979.055 3.979.055 1.45.685 3.189-.283 3.189-.283 1.582.538 3.658-.859 3.658-.859 2.648.448 3.573-1.863 3.573-1.863 1.637.037 2.107-1.634 2.107-1.634 1.786.173 2.123-1.826 2.123-1.826 1.45-.96 1.606-2.32 1.606-2.32.345-2-1.316-2.611-1.316-2.611zM78.688 1.9s4.912.712 6.957 4.054c0 0 4.09.712 4.912 5.25 0 0 3.886 2.383 3.886 7.16 0 0 4.708 5.724 2.664 8.583 0 0 3.893 9.122 1.026 12.884-1.637 2.146-.204 1.196-4.301.237-4.097-.958-13.099-7.159-13.099-7.159s-5.782-4.821-6.808-5.542-3.839 23.43 1.896 32.973h-2.248s-5.116-7.159-1.638-34.115c0 0-13.537-9.852-18.034-16.29-4.505-6.438.345-7.14 4.23-6.903 0 0 2.476-2.192 6.77-1.48 0 0 2.396-1.972 5.78-.164 0 0 4.153-2.922 8 .511z"
      />
      <path
        fill="#fff"
        d="M58.273 11.743s4.795 1.808 10.443 6.082c.424.164 5.923.328 10.726 6.903 0 0 2.538 1.808 5.217 6.739 0 0 .705.164 2.397 2.137 1.693 1.972 3.103 3.287 4.09 3.616"
      />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M58.273 11.743s4.795 1.808 10.443 6.082c.424.164 5.923.328 10.726 6.903 0 0 2.538 1.808 5.217 6.739 0 0 .705.164 2.397 2.137 1.693 1.972 3.103 3.287 4.09 3.616"
      />
      <path fill="#fff" d="M82.533 9.113s-4.653 5.917-4.935 9.04z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M82.533 9.113s-4.653 5.917-4.935 9.04"
      />
      <path fill="#fff" d="M79.008 14.218s3.243-2.137 7.474.164z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M79.008 14.218s3.243-2.137 7.474.164"
      />
      <path fill="#fff" d="M78.023 20.463s7.615-4.437 12.84.493z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M78.023 20.463s7.615-4.437 12.84.493"
      />
      <path fill="#fff" d="M81.688 21.12s5.781-.82 13.263 8.383z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M81.688 21.12s5.781-.82 13.263 8.383"
      />
      <path
        fill="#fff"
        d="M80.844 23.586s3.807.165 5.5 3.452c1.692 3.287 5.358 8.218 5.781 8.218"
      />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M80.844 23.586s3.807.165 5.5 3.452c1.692 3.287 5.358 8.218 5.781 8.218"
      />
      <path fill="#fff" d="M71.816 6.812s3.244 1.808 4.09 9.871z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M71.816 6.812s3.244 1.808 4.09 9.871"
      />
      <path fill="#fff" d="M64.195 8.127s2.962-1.15 6.91 1.315z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M64.195 8.127s2.962-1.15 6.91 1.315"
      />
      <path
        fill="#fff"
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M.572 74.11s-.987-2.904.235-5.816c0 0-1.285-4.01 2.86-5.644 0 0-.142-4.62 3.117-4.647 0 0-.039-3.133 3.76-2.804 0 0 1.113-4.145 4.882-2.429 0 0 3.055-2.666 5.656-.091 0 0 3.267-1.114 4.77 2.822 0 0 3.832-1.726 5.171 4.848 0 0 4.889 1.89 4.63 5.662-.219 3.132.674 2.894.087 4.401 3.752 1.15 6.345 3.379 8.398 5.588l-2.57-.054s-3.063-3.799-7.607-4.027z"
      />
      <path fill="#fff" d="M12.856 56.907s7.09 5.269 7.105 10.656z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M12.856 56.907s7.09 5.269 7.105 10.656"
      />
      <path fill="#fff" d="M17.932 67.052s-6.01-7.388-9.159-6.264z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M17.932 67.052s-6.01-7.388-9.159-6.264"
      />
      <path fill="#fff" d="M22.078 55.555s1.927 1.552 1.442 3.835z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M22.078 55.555s1.927 1.552 1.442 3.835"
      />
      <path fill="#fff" d="M19.438 57.473s3.744 4.985 3.674 7.223z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M19.438 57.473s3.744 4.985 3.674 7.223"
      />
      <path
        fill="#fff"
        d="M6.833 70.038c.235-4.036 4.105-4.767 4.105-4.767 2.97-4.483 5.5-1.936 5.5-1.936 1.786-3.35 5.186-1.123 5.186-1.123 2.272-1.981 4.936.767 4.936.767s2.005-.904 3.846 3.461c0 0 5.288 3.114 4.638 5.725 0 0-2.663 1.498-12.346 2.877-8.822 1.26-11.854-1.845-17.385-.228-4.418 1.287-6.44.036-3.337-3.324 2.342-2.539 4.85-1.443 4.85-1.443"
      />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M6.833 70.038c.235-4.036 4.105-4.767 4.105-4.767 2.97-4.483 5.5-1.936 5.5-1.936 1.786-3.35 5.186-1.123 5.186-1.123 2.272-1.981 4.936.767 4.936.767s2.005-.904 3.846 3.461c0 0 5.288 3.114 4.638 5.725 0 0-2.663 1.498-12.346 2.877-8.822 1.26-11.854-1.845-17.385-.228-4.418 1.287-6.44.036-3.337-3.324 2.342-2.539 4.85-1.443 4.85-1.443"
      />
      <path fill="#fff" d="M17.793 67.746s3.549 1.662 4.873 3.497z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M17.793 67.746s3.549 1.662 4.873 3.497"
      />
      <path
        fill="#fff"
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M123.013 41.694s1.073-3.68-1.16-6.164c0 0 .016-4.748-4.841-4.593 0 0-1.379-4.867-4.772-3.433 0 0-.987-3.279-4.841-1.233 0 0-2.523-3.817-5.884-.338 0 0-4.058-1.406-5.914 2.447 0 0-3.769.301-4.035 5.077 0 0-4.56-.073-3.776 7.37 0 0-3.087 3.26-1.567 7.076 1.254 3.16.024 2.228 1.136 3.534-1.528.977-3.917 2.922-5.327 6.136l-.173 4.191s3.73-8.163 8.038-8.802l-.352-.73 33.46-10.53z"
      />
      <path fill="#fff" d="M105.567 29.092s-5.64 8.666-3.878 14.273z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M105.567 29.092s-5.64 8.666-3.878 14.273"
      />
      <path fill="#fff" d="M103.621 41.931s3.815-10.382 7.466-10.629z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M103.621 41.931s3.815-10.382 7.466-10.629"
      />
      <path fill="#fff" d="M108.73 34.197s6.127.246 7.106-1.06z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M108.73 34.197s6.127.246 7.106-1.06"
      />
      <path fill="#fff" d="M95.523 31.814s-1.496 2.483-.227 4.638z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M95.523 31.814s-1.496 2.483-.227 4.638"
      />
      <path fill="#fff" d="M98.898 32.627s-2.249 6.866-1.442 9.158z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M98.898 32.627s-2.249 6.866-1.442 9.158"
      />
      <path
        fill="#fff"
        d="M116.159 40.05c-1.574-4.09-5.844-3.123-5.844-3.123-4.567-3.333-6.361.448-6.361.448-2.969-2.685-5.766 1.15-5.766 1.15-3.016-1.05-4.881 3.014-4.881 3.014s-2.39-.037-2.86 5.323c0 0-3.133 4.073-1.598 6.502 0 0 6.816-2.283 10.482-2.466 3.667-.182 14.933-2.821 19.328-5.725s7.371-3.698 3.031-5.799c-3.274-1.588-5.523.676-5.523.676"
      />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M116.159 40.05c-1.574-4.09-5.844-3.123-5.844-3.123-4.567-3.333-6.361.448-6.361.448-2.969-2.685-5.766 1.15-5.766 1.15-3.016-1.05-4.881 3.014-4.881 3.014s-2.39-.037-2.86 5.323c0 0-3.133 4.073-1.598 6.502 0 0 6.816-2.283 10.482-2.466 3.667-.182 14.933-2.821 19.328-5.725s7.371-3.698 3.031-5.799c-3.274-1.588-5.523.676-5.523.676"
      />
      <path fill="#fff" d="M103.999 42.589s-3.149 3.324-3.917 5.817z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M103.999 42.589s-3.149 3.324-3.917 5.817"
      />
      <path
        fill="#fff"
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M72.318 42.534c-6.377-14.4-14.415-12.692-14.415-12.692l-.15 1.205c10.64.858 12.998 14.811 12.998 14.811l1.253-.52c10.64 8.108 11.673 31.083 11.673 31.083 1.622.858 2.217-.174 2.217-.174C82.988 51.218 75.561 44.36 72.31 42.534z"
      />
      <path
        fill="#fff"
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M34.644 27.595s-2.444.192-4.364 2.593c0 0-2.796.977-4.16 3.397 0 0-2.154.667-3.172 3.397 0 0-1.724.64-2.586 4.347 0 0-1.754 2.821-1.277 5.743 0 0-1.621 2.329.063 4.959 0 0 1.05 1.844 3.4 1.99 0 0 1.677 2.63 3.721.822 0 0 1.826 1.041 3.776-.43 0 0 2.076 1.681 4.99-1.26 0 0 2.398.521 3.965-1.643 0 0 2.703-.1 4.019-2.356 0 0 2.86.877 4.763-3.543 0 0 1.575.95 3.588-2.328 0 0 3.275-1.17 4.011-4.913 0 0 2.492-.356 3.071-5.077 0 0 .948-3.488-.791-4.858 0 0-.212-3.37-4.082-2.602 0 0-1.167-2.439-4.395-1.443 0 0-2.068-2.228-5.405.2 0 0-3.118-.967-4.654 1.571 0 0-2.679-1.534-4.48 1.452z"
      />
      <path fill="#fff" d="M47.862 33.969s-8.124-3.096-13.74 1.89z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M47.862 33.969s-8.124-3.096-13.74 1.89"
      />
      <path fill="#fff" d="M31.901 37.585s-4.724 3.442-5.17 6.373z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M31.901 37.585s-4.724 3.442-5.17 6.373"
      />
      <path fill="#fff" d="M36.335 36.553S31.9 41.027 31.165 47.4z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M36.335 36.553S31.9 41.027 31.165 47.4"
      />
      <path fill="#fff" d="M42.69 33.969s-3.102.858-3.69 6.374z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M42.69 33.969s-3.102.858-3.69 6.374"
      />
      <path fill="#fff" d="M40.918 35.348s0 4.136 2.366 7.058z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M40.918 35.348s0 4.136 2.366 7.058"
      />
      <path fill="#fff" d="M38.704 41.895s-1.77 3.269-.447 4.994z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M38.704 41.895s-1.77 3.269-.447 4.994"
      />
      <path fill="#fff" d="M41.21 28.636c.424.064 7.302.173 8.274 4.821z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M41.21 28.636c.424.064 7.302.173 8.274 4.821"
      />
      <path fill="#fff" d="M38.109 33.457s-2.366-2.237-5.32-1.379z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M38.109 33.457s-2.366-2.237-5.32-1.379"
      />
      <path
        fill="#fff"
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M62.946 36.38s-2.577-1.909-7.497.273c0 0-2.813-.821-4.685.548-1.873 1.37-4.215 1.096-5.625 4.1 0 0-3.745 1.096-4.215 4.913 0 0-1.872 1.908-1.637 6.008 0 0-.94 1.37-1.175 5.46 0 0-.94 3.28.47 6.01 0 0-.47 3.825 2.107 4.638 0 0-.705 3.552 2.107 5.46 0 0 1.403 3.827 4.45 3.553 0 0 3.048 2.73 6.323.822 0 0 2.577 3.004 5.154.821 0 0 3.048 1.096 4.45-1.37 0 0 4.92 1.37 5.86-3.004 0 0 4.215-.273 3.98-4.364 0 0 3.4-1.096 1.99-5.324 0 0 3.282-1.096 1.52-6.145 0 0 2.577-5.735-.47-7.917 0 0 0-6.83-5.39-7.37 0 0-.235-7.368-7.733-7.094z"
      />
      <path fill="#fff" d="M65.524 48.807s-9.017 4.1-14.29 12.291z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M65.524 48.807s-9.017 4.1-14.29 12.291"
      />
      <path fill="#fff" d="M50.535 62.596s-2.225 2.867-1.755 6.967z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M50.535 62.596s-2.225 2.867-1.755 6.967"
      />
      <path fill="#fff" d="M61.895 52.898s-4.333 5.871-4.333 10.236z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M61.895 52.898s-4.333 5.871-4.333 10.236M57.563 64.23l-.235 3.004"
      />
      <path fill="#fff" d="M57.564 68.741s-.47 2.868.94 4.913z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M57.564 68.741s-.47 2.868.94 4.913"
      />
      <path fill="#fff" d="M65.522 50.99s-1.402 2.73-1.285 6.42l.588 4.638" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M65.522 50.99s-1.402 2.73-1.285 6.42l.588 4.638"
      />
      <path fill="#fff" d="M65.172 63.144s.352 3.552 2.46 5.46z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M65.172 63.144s.352 3.552 2.46 5.46"
      />
      <path fill="#fff" d="M62.948 58.907s-1.755 2.73-1.638 5.186z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M62.948 58.907s-1.755 2.73-1.638 5.186"
      />
      <path fill="#fff" d="M66.465 50.03s.117 4.502 2.577 5.461z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M66.465 50.03s.117 4.502 2.577 5.461"
      />
      <path fill="#fff" d="M64.826 47.026s-3.628-.41-4.685-4.364z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M64.826 47.026s-3.628-.41-4.685-4.364"
      />
      <path fill="#fff" d="M53.34 55.217c0-.41-3.863-3.552-9.84-1.095z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M53.34 55.217c0-.41-3.863-3.552-9.84-1.095"
      />
      <path fill="#fff" d="M47.49 53.857s-4.097 2.73-3.98 5.598z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M47.49 53.857s-4.097 2.73-3.98 5.598"
      />
      <path fill="#fff" d="M60.14 50.03s-8.437.412-12.535-4.912z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M60.14 50.03s-8.437.412-12.535-4.912"
      />
      <path fill="#fff" d="M93.594 64.842s5.28-5.25 10.928-5.178z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M93.594 64.842s5.28-5.25 10.928-5.178"
      />
      <path fill="#fff" d="M106.68 59.564s4.544-.338 5.703.977z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M106.68 59.564s4.544-.338 5.703.977"
      />
      <path fill="#fff" d="M101.586 61.865s4.027.676 6.236 3.726z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M101.586 61.865s4.027.676 6.236 3.726"
      />
      <path fill="#fff" d="M96.863 62.897s2.202-.712 4.074 1.936z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M96.863 62.897s2.202-.712 4.074 1.936"
      />
      <path fill="#fff" d="M98.36 62.952s1.127 2.155.43 4.575z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M98.36 62.952s1.127 2.155.43 4.575"
      />
      <path fill="#fff" d="M101.547 65.536s2.013 1.041 1.645 2.438z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M101.547 65.536s2.013 1.041 1.645 2.438"
      />
      <path fill="#fff" d="M96.337 59.555c-.251.191-4.575 2.83-3.917 5.625z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M96.337 59.555c-.251.191-4.575 2.83-3.917 5.625"
      />
      <path fill="#fff" d="M99.621 60.906s.885-2.054 2.985-2.712z" />
      <path
        stroke="#9a83a9"
        strokeMiterlimit={10}
        strokeWidth={0.5}
        d="M99.621 60.906s.885-2.054 2.985-2.712"
      />
    </g>
  </svg>
);
export default SvgSetGraphD02;
