import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'
import { pageMaxWidth } from '../styles/page-max-width'

const Outer = styled.div`
  ${pageMaxWidth}
`

const Caution = styled.div`
  font-size: 0.875rem;
  line-height: 2;
  padding: 1.5rem;
  text-align: center;
  background-color: #CC4444;
  color: white;
  margin-top: 1.5rem;
  border-radius: 0.5rem;
  font-weight: 700;
`

type CautionSectonProps = {
  children?: ReactNode
}
export const CautionSecton: FunctionComponent<CautionSectonProps> = ({children}) => {
  return <Outer>
    <Caution>
      {children}
    </Caution>
  </Outer>
}