import React, { FunctionComponent, ReactNode, useState } from 'react'
import styled from 'styled-components'
import SvgBottomArrow from '../generated/svg/bottom-arrow'
import classNames from 'classnames'
import { useBreakpoint } from '../hooks/use-break-point'
import { DetailTable } from './detail-table'
import { AnimateArrow } from './animate-arrow'
import { HydroLightHowTo, NiacinaGlowHowTo, PeelCareHowTo, RetinoDermHowTo } from './how-to'
import SvgGl11 from '../generated/svg/gl1-1'
import SvgGl21 from '../generated/svg/gl2-1'
import SvgGl31 from '../generated/svg/gl3-1'
import SvgGl41 from '../generated/svg/gl4-1'

const Outer = styled.div`

`

const Head = styled.div`
  font-size: 1.375rem;
  line-height: 1.7272727272727273;
  letter-spacing: 0.08em;
  font-weight: 700;
  margin-bottom: 2rem;
`

const TabContainer = styled.div`
  display: flex;
  position: relative;
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 0.175rem;
    width: 100%;
    background-image: var(--border-color);
  }
`

const Tab = styled.div`
  flex: 1 1 0;
  line-height: 2.375;
  letter-spacing: 0.08em;
  font-weight: 700;
  padding: 1rem 0;
  text-align: center;
  user-select: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Name = styled.div`
  svg {
    display: block;
    .inactive & path[fill]{
      fill: #959292 !important;
    }
  }
`

const Label = styled.div`
  font-size: 0.75rem;
  letter-spacing: 0.08em;
  margin-top: 1rem;
`

type TabId = 'peelCare' | 'retinoDerm' | 'hydroLight' | 'niacinaGlow'
type TabType = {
  id: TabId,
  name: ReactNode,
  label: ReactNode,
  content: FunctionComponent
  borderColors: string[]
}

const tabs: TabType[] = [
  {
    id: 'peelCare',
    name: <SvgGl11 />,
    label: 'ピールケアセラム(1.0/2.0/3.0)',
    borderColors: [
      '#B7CEB4',
      '#9ABBBE',
      '#5395A1'
    ],
    content: () => {
      return <PeelCareHowTo />
    }
  },
  {
    id: 'retinoDerm',
    name: <SvgGl21 />,
    label: 'レチノダーマクリーム(0.1/0.2./0.4)',
    borderColors: [
      '#EFBAB4',
      '#E98760',
    ],
    content: () => {
      return <RetinoDermHowTo />
    }
  },
  {
    id: 'hydroLight',
    name: <SvgGl41 />,
    label: 'ハイドロライトクリーム(2.5/4.0)',
    borderColors: [
      '#C6B7D8',
      '#A98EBB',
    ],
    content: () => {
      return <HydroLightHowTo />
    }
  },
  {
    id: 'niacinaGlow',
    name: <SvgGl31 />,
    label: 'ナイアシグロージェル',
    borderColors: [
      '#D9AFCB',
    ],
    content: () => {
      return <NiacinaGlowHowTo />
    }
  },
]

type UsageGuideProps = {
  use: [TabId, ...TabId[]],
  initial?: TabId | undefined
}
export const UsageGuide: FunctionComponent<UsageGuideProps> = ({use, initial}) => {
  const hasTab = use.length > 1
  const [activeTab, setActiveTab] = useState<TabId>(initial ?? use[0])
  const activeTabs = tabs.filter(tab => use.includes(tab.id))
  const tab = tabs.find(tab => tab.id === activeTab)
  const isMobile = useBreakpoint('40rem')
  if(!tab) return null
  return <Outer>
    <Head>
      使用方法
    </Head>
    {
      isMobile ? <>
        <DetailTable
          items={activeTabs.map(tab => ({
            label: <Tab>
              <Name>{tab.name}</Name>
              <Label>{tab.label}</Label>
            </Tab>,
            value: <tab.content />
          }))}
        />
      </> : <>
        {
          hasTab && <TabContainer style={{'--border-color': `linear-gradient(to right, ${tab.borderColors.length > 1 ? tab.borderColors.join(',') : `${tab.borderColors[0]}, ${tab.borderColors[0]}`})`}}>
            {
              tabs.filter(tab => use.includes(tab.id)).map(tab => {
                return <Tab key={tab.id} className={classNames({inactive: tab.id !== activeTab})} onClick={() => setActiveTab(tab.id)}>
                  <Name>{tab.name}</Name>
                  <Label>{tab.label}</Label>
                </Tab>
              })
            }
          </TabContainer>
        }
        <tab.content />
      </>
    }
  </Outer>
}