import React, { FunctionComponent, ReactNode } from 'react'
import { ModalProvider } from '../hooks/use-modal'

type PageRootProps = {
  children?: ReactNode
}
export const PageRoot: FunctionComponent<PageRootProps> = ({children}) => {
  return <ModalProvider>
    {children}
  </ModalProvider>
}