import React, { Fragment, FunctionComponent, ReactNode, useState } from 'react'
import styled from 'styled-components'
import { AssetImage } from './image'
import { pageMaxWidth } from '../styles/page-max-width'
import { ProductCheckoutPopup } from './checkout-popup'
import { Animate } from './animate'
import { Variations } from './variations'
import classNames from 'classnames'
import { SKU } from '../product-sku'
import { ProductData, getProductData, normalizePrice } from '../utils/get-product-data'
import { useProductData } from '../hooks/use-product-data'
import { isNotNullish, isNullish } from '../utils/type-check'

const Outer = styled.div`
  ${pageMaxWidth}
  @media (max-width: 40rem){
    overflow-x: clip;
  }
`

const Inner = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 6fr minmax(max-content, 4fr);
  @media (max-width: 40rem){
    grid-template-columns: 1fr;
    gap: 3rem;
  }
`

const ImageContainer = styled.div`
  opacity: 0;
  transform: translateY(2rem);
  transition: opacity, transform;
  position: relative;
  min-width: 0;
  
  @media (max-width: 40rem){
    min-height: 16rem;
  }
  .animate & {
    opacity: 1;
    transform: none;
    transition: 1.2s 0.2s, 1.2s 0.2s;
  }
  img {
    display: block;
    width: 40rem;
    height: auto;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    @media (max-width: 60rem){
      width: 34rem;
    }
    @media (max-width: 40rem){
    }
  }
`

const TextContainer = styled.div`
  position: relative;
  z-index: 2;
  @media (max-width: 40rem){
    align-self: center;
    justify-self: center;
    width: fit-content;
  }
`

const Brand = styled.div`
  font-size: 0.75rem;
  line-height: normal;
  letter-spacing: 0.06em;
  font-family: var(--en-font);
`

const Name = styled.div`
  font-family: var(--en-font);
  font-size: 1.375rem;
  line-height: normal;
  letter-spacing: 0.06em;
  font-weight: 700;
  margin-top: 0.75rem;
`

const NameJa = styled.div`
  font-size: 0.875rem;
  line-height: normal;
  font-weight: 700;
  margin-top: 0.5rem;
`

const InfoContainer = styled.div`
  display: grid;
  width: fit-content;
  grid-template-columns: repeat(2, auto);
  margin-top: 1.5rem;
  column-gap: 3rem;
  row-gap: 1.5rem;
`

const InfoItem = styled.div`
  display: grid;
  grid-template-columns: subgrid;
  grid-column: span 2;
`

const InfoLabel = styled.div`
  font-size: 0.8125rem;
  line-height: 1.3846153846153846;
  font-weight: 700;
`

const InfoValue = styled.div`
  font-size: 0.8125rem;
  line-height: 1.3846153846153846;
  font-weight: 500;
`

const PriceTable = styled.div`
  display: grid;
  width: fit-content;
  grid-template-columns: repeat(2, auto);
  gap: 1rem;
`

const Price = styled.div`
  line-height: 1.2;
  font-weight: 700;
  font-size: 1rem;
  span {
    font-size: 0.625rem;
    font-weight: 500;
  }
  small {
    font-size: 0.75rem;
    font-weight: 500;
  }
`

const PriceDiscount = styled(Price)`
  color: #A91D2E;
  transform: translateY(-25%);
`

const BuyNowButton = styled.div`
  display: block;
  background-color: #D9D1BE;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: var(--en-font);
  font-weight: 700;
  text-align: center;
  padding: 0.75rem 0;
  border-radius: 10rem;
  margin-top: 1rem;
  cursor: pointer;
  user-select: none;
  @media (max-width: 40rem){
    width: 100%;
    max-width: 12rem;
    justify-self: center;
    align-self: center;
    ${InfoItem}:has(&) {
      grid-template-columns: 1fr;
    }
  }
`

const Note = styled.div`
  font-size: 0.8rem;
  line-height: 1.5;
  font-weight: 500;
`

const VariationOUter = styled.div`
`

const PriceNotice = styled.div`
  background-color: #d5c192;
  color: white;
  font-size: 0.8rem;
  padding: 0.5rem;
  text-align: center;
  font-weight: 700;
  width: 100%;
  box-sizing: border-box;
  grid-column: span 2;
  border-radius: 0.25rem;
`

type PriceData = {
  price: ReactNode,
  discount?: ReactNode
}

export type ProductInfoData = {
  images: string[],
  brandName: ReactNode,
  name: ReactNode,
  nameJa: ReactNode,
  info: {
    label: ReactNode,
    value: ReactNode
  }[],
  originalSku?: SKU | null,
  singleSku: SKU | null,
  subscriptionSku: SKU | null,
  checkoutNote: ReactNode,
  variations?: {
    label: ReactNode,
    color: string,
    active?: boolean,
    link: string
  }[],
  buyButtonLabel?: ReactNode,
  buyButtonLink?: string,
  note?: ReactNode,
  notOnSale?: boolean,
  agreement?: ReactNode,
  notice?: ReactNode,
  priceLabelPrefix?: ReactNode,
  priceNotice?: ReactNode,
  subscriptionNotice?: ReactNode | ((data: {originalSingle: ProductData | undefined, single: ProductData | undefined, subscription: ProductData | undefined}) => ReactNode),
  subscriptionPriceOverride?: number,
  subscriptionNormalPrice?: number
}

type ProductInfoProps = {
  data: ProductInfoData
}
export const ProductInfo: FunctionComponent<ProductInfoProps> = ({data}) => {
  const originalSingleData = useProductData(data.originalSku?.sku)
  const singleData = useProductData(data.singleSku?.altSku ?? data.singleSku?.sku)
  const subscriptionData = useProductData(data.subscriptionSku?.altSku ?? data.subscriptionSku?.sku)
  const [open, setOpen] = useState(false)
  return <Outer>
    <Inner>
      <Animate target="child">
        <ImageContainer>
          <AssetImage src={data.images[0] ?? ''} />
        </ImageContainer>
      </Animate>
      <TextContainer>
        <Brand>{data.brandName}</Brand>
        <Name>{data.name}</Name>
        <NameJa>{data.nameJa}</NameJa>
        <InfoContainer>
          {
            data.info.map((row, i) => {
              return <InfoItem key={i}>
                <InfoLabel>
                  {row.label}
                </InfoLabel>
                <InfoValue>
                  {row.value}
                </InfoValue>
              </InfoItem>
            })
          }
          {
            data.priceNotice && <InfoItem>
              <PriceNotice>
                {data.priceNotice}
              </PriceNotice>
            </InfoItem>
          }
          {
            originalSingleData &&
            <InfoItem>
              <InfoLabel>
                通常単品価格
              </InfoLabel>
              <InfoValue>
                <PriceTable>
                  <Price>
                    {normalizePrice(originalSingleData?.sales_price_include_tax).toLocaleString('en-US')}<small>円(税込)</small>
                  </Price>
                </PriceTable>
              </InfoValue>
            </InfoItem>
          }
          {
            singleData &&
            <InfoItem>
              <InfoLabel>
                {data.priceLabelPrefix}単品価格
              </InfoLabel>
              <InfoValue>
                <PriceTable>
                  <Price>
                    {normalizePrice(singleData?.sales_price_include_tax).toLocaleString('en-US')}<small>円(税込)</small>
                  </Price>
                  {
                    originalSingleData &&
                    <PriceDiscount>
                      <span>通常価格より</span><br />
                      <span>約</span>{Math.round((1 - (normalizePrice(singleData.sales_price_include_tax) / normalizePrice(originalSingleData.sales_price_include_tax))) * 100)}<small>%OFF</small>
                    </PriceDiscount>
                  }
                </PriceTable>
              </InfoValue>
            </InfoItem>
          }
          {
            subscriptionData &&
            <InfoItem>
              <InfoLabel>
                {data.priceLabelPrefix}定期価格
              </InfoLabel>
              <InfoValue>
                <PriceTable>
                  <Price>{(data.subscriptionPriceOverride ?? normalizePrice(subscriptionData.sales_price_include_tax)).toLocaleString('en-US')}<small>円(税込)</small></Price>
                  {
                    singleData &&
                    <PriceDiscount>
                      <span>通常価格より</span><br />
                      <span>約</span>{Math.round((1 - ((data.subscriptionPriceOverride ?? normalizePrice(subscriptionData.sales_price_include_tax)) / normalizePrice(originalSingleData?.sales_price_include_tax ?? singleData.sales_price_include_tax))) * 100)}<small>%OFF</small>
                    </PriceDiscount>
                  }
                </PriceTable>
              </InfoValue>
            </InfoItem>
          }
          {
            data.variations && <InfoItem>
              <InfoLabel>
                GRADE<br />
                SELECT
              </InfoLabel>
              <InfoValue>
                <VariationOUter>
                  <Variations items={data.variations} />
                </VariationOUter>
              </InfoValue>
            </InfoItem>
          }
          {
            data.note && <InfoItem>
              <InfoLabel style={{gridColumn: 'span 2'}}>
                <Note>
                  {data.note}
                </Note>
              </InfoLabel>
            </InfoItem>
          }
          {
            data.notOnSale || <InfoItem>
              <InfoLabel style={{gridColumn: 'span 2'}}>
                {
                  data.buyButtonLink ?
                  <BuyNowButton as="a" href={data.buyButtonLink} >{data.buyButtonLabel || 'BUY NOW'}</BuyNowButton> :
                  <BuyNowButton onClick={() => setOpen(true)}>{data.buyButtonLabel || 'BUY NOW'}</BuyNowButton>
                }
              </InfoLabel>
            </InfoItem>
          }
          {
            (originalSingleData || singleData)?.option06 && <InfoItem>
              <InfoLabel style={{gridColumn: 'span 2'}}>
                <Note>{(originalSingleData || singleData)?.option06}</Note>
              </InfoLabel>
            </InfoItem>
          }
        </InfoContainer>
      </TextContainer>
    </Inner>
    <ProductCheckoutPopup
      data={{
        brandName: data.brandName,
        name: data.name,
        nameJa: data.nameJa,
        items: [
          ...(data.singleSku && singleData ? [
            {
              id: data.singleSku.altId ?? data.singleSku.id,
              label: `単体`,
              prices: [
                {
                  price: <>{normalizePrice(singleData.sales_price_include_tax).toLocaleString('en-US')}<small>円(税込)</small></>,
                  ...(originalSingleData ? {
                    discount: <>
                      <span>通常価格より</span><br />
                      <span>約</span>{Math.round((1 - (normalizePrice(singleData.sales_price_include_tax) / normalizePrice(originalSingleData?.sales_price_include_tax ?? singleData.sales_price_include_tax))) * 100)}<small>%OFF</small>
                    </>
                  } : {
                  })
                }
              ]
            }
          ] : []),
          ...(data.subscriptionSku && subscriptionData ? [
            {
              id: data.subscriptionSku.altId ?? data.subscriptionSku.id,
              label: `定期便`,
              prices: [
                {
                  price: <>
                    {(data.subscriptionPriceOverride ?? normalizePrice(subscriptionData.sales_price_include_tax)).toLocaleString('en-US')}<small>円(税込)</small>
                  </>,
                  ...(singleData ? {
                    discount: <>
                      <span>通常価格より</span><br />
                      <span>約</span>{Math.round((1 - ((data.subscriptionPriceOverride ?? normalizePrice(subscriptionData.sales_price_include_tax)) / normalizePrice(originalSingleData?.sales_price_include_tax ?? singleData.sales_price_include_tax))) * 100)}<small>%OFF</small>
                    </>
                  } : {
                  })
                }
              ]
            }
          ] : [])
        ],
        note: data.checkoutNote,
        notice: data.notice,
        agreement: data.agreement,
        subscriptionNotice: typeof data.subscriptionNotice === 'function' ? data.subscriptionNotice({originalSingle: originalSingleData, single: singleData, subscription: subscriptionData}) : data.subscriptionNotice ? data.subscriptionNotice : subscriptionData && <>
          ※定期便購入回数のお約束はなく、解約・休止が可能です。<br />
          ※定期便の初回価格は{(data.subscriptionPriceOverride ?? normalizePrice(subscriptionData.sales_price_include_tax)).toLocaleString('en-US')}円(税込)、2回目以降の価格{((isNullish(data.subscriptionPriceOverride) && isNotNullish(data.subscriptionNormalPrice)) && normalizePrice(subscriptionData.sales_price_include_tax) === Math.floor(subscriptionData.master.sales_price * 1.1)) ? 'も' : 'は'}{(data.subscriptionNormalPrice ?? data.subscriptionPriceOverride ?? Math.floor(subscriptionData.master.sales_price * 1.1)).toLocaleString('en-US')}円(税込)で発送いたします。<br />
          ※60日毎お届けコース<br />
          ※定期便の解約は、次回商品発送予定日の7日前までにお電話（0120-488-873）、<br />
          または問い合わせフォーム（https://skn-remed.com/shop/contact）にてご連絡下さい。
        </>
      }}
      open={open}
      onClose={() => setOpen(false)}
    />
  </Outer>
}