import classNames from 'classnames'
import React, { FunctionComponent, ReactNode } from 'react'
import styled from 'styled-components'

const Outer = styled.div`
  display: flex;
  gap: 0.75rem;
  @media (max-width: 40rem){
    order: -1;
    align-self: center;
  }
`

const Variation = styled.a`
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--serif-en-font);
  font-weight: 400;;
  background-color: var(--color);
  position: relative;
  border-radius: 10rem;
  color: white;
  font-size: 0.8rem;
  opacity: 0.3;
  transition: opacity 0.4s;
  box-sizing: border-box;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  &.active {
    opacity: 1;
    box-shadow: inset 0.125rem 0.125rem 0.25rem #0004;
  }
  &::after {
    content: '';
    display: block;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 10rem;
    top: 50%;
    left: 50%;
    border: 2px solid transparent;
    transform: translate(-50%, -50%);
    position: absolute;
  }
  &.active::after {
    background: var(--ring-gradient) border-box border-box;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0) border-box;
    mask-composite: exclude;
    opacity: 1;
  }
`

type VariationsProps = {
  items: {
    label: ReactNode,
    color: string,
    active?: boolean,
    link: string
  }[]
}
export const Variations: FunctionComponent<VariationsProps> = ({items}) => {
  return <Outer>
    {
      items.map((v, i) => <Variation key={i} href={v.link} className={classNames({active: v.active})} style={{'--color': v.color, '--ring-gradient': `linear-gradient(to right bottom, ${items.map(i => i.color).join(',')})`}}>{v.label}</Variation>)
    }
  </Outer>
}